import { isAndroidWebView, isLinux } from '@eventbrite/feature-detection';
import { initializeSdk, StatsigConfiguration } from '@eventbrite/statsig';
import { isTicketSelectionExcludedInListings } from '../components/EventDetails/ConversionBarContainer/isTicketSelectionExcludedInListings';
import type { AppProps } from '../types';
import { isRepeatingEvent, isYTShortVerticalVideo } from '../utils';
import { normalizeCategory } from './idUtils';
import { evaluationCallback } from './utils';

const shouldShowCompactCheckout = (app: AppProps) => {
    const allowedDevicesForCompactCheckout = !isLinux() || isAndroidWebView();

    const compactCheckoutDisqualifications =
        app.event?.compactCheckoutDisqualifications;

    return (
        allowedDevicesForCompactCheckout &&
        !isTicketSelectionExcludedInListings({
            soldOut: compactCheckoutDisqualifications.sold_out,
            reservedSeating: compactCheckoutDisqualifications.reserved_seating,
            isSalesEnded: compactCheckoutDisqualifications.is_sales_ended,
            isRsvp: compactCheckoutDisqualifications.is_rsvp,
            isPostponed: compactCheckoutDisqualifications.is_postponed,
            isCanceled: compactCheckoutDisqualifications.is_canceled,
            moreThanOneTicket:
                compactCheckoutDisqualifications.more_than_one_ticket,
            hasWaitlist: compactCheckoutDisqualifications.has_waitlist,
            hasWaitingRoom: compactCheckoutDisqualifications.has_waiting_room,
            hasDiscounts: compactCheckoutDisqualifications.has_discounts,
            hasAddons: compactCheckoutDisqualifications.has_addons,
            discountCode: compactCheckoutDisqualifications.discount_code,
            hasDonations: compactCheckoutDisqualifications.has_donations,
            externalTicketsAreAvailable:
                app.components?.conversionBar.externalTicketsAreAvailable,
            salesStatus: app.event?.salesStatus.sales_status,
            hasInventoryTiers:
                compactCheckoutDisqualifications.has_inventory_tiers,
        })
    );
};

const getDisqualificationProperties = (app: AppProps) => {
    const compactCheckoutDisqualifications =
        app.event?.compactCheckoutDisqualifications;

    return {
        hasDiscountCode: compactCheckoutDisqualifications.discount_code,
        hasAddons: compactCheckoutDisqualifications.has_addons,
        hasDiscount: compactCheckoutDisqualifications.has_discounts,
        hasInventoryTiers: compactCheckoutDisqualifications.has_inventory_tiers,
        hasWaitingRoom:
            compactCheckoutDisqualifications.has_not_waiting_room ??
            compactCheckoutDisqualifications.has_waiting_room,
        hasWaitlist: compactCheckoutDisqualifications.has_waitlist,
        isCanceled: compactCheckoutDisqualifications.is_canceled,
        isPostponed: compactCheckoutDisqualifications.is_postponed,
        isRSVP: compactCheckoutDisqualifications.is_rsvp,
        isSalesEnded: compactCheckoutDisqualifications.is_sales_ended,
        hasMoreThanOneTicket:
            compactCheckoutDisqualifications.more_than_one_ticket,
        totalTicketsCount: app.event?.ticketsInfo.totalTicketsCount,
        isReservedSeating: compactCheckoutDisqualifications.reserved_seating,
        isSoldOut: compactCheckoutDisqualifications.sold_out,
        repeatingEvent: compactCheckoutDisqualifications.repeating_event,
        hasDonatios: compactCheckoutDisqualifications.has_donations,
    };
};

export const initializeStatsigSdk = async (
    app: AppProps,
    stableId?: string,
) => {
    const options: StatsigConfiguration['options'] = {
        evaluationCallback: evaluationCallback,
    };

    if (stableId) {
        options.overrideStableID = app.stableId;
    }

    const disqualificationProperties = getDisqualificationProperties(app);

    await initializeSdk({
        user: {
            userID: app.user.publicId,
            custom: {
                userIsAuthenticated: app.user.isAuthenticated,
                eventId: app.event?.id,
                category: normalizeCategory(app.event?.category),
                subcategory: normalizeCategory(app.event?.subcategory),
                organizerId: app.organizer?.id,
                ebDomain: app.env?.ebDomain,
                shouldShowCompactCheckout: shouldShowCompactCheckout(app),
                isRepeatingEvent: isRepeatingEvent(app),
                onlineEvent: app.event?.isOnlineEvent,
                isSeriesParent: app.event?.isSeriesParent,
                isSeries: !!app.event?.childEvents?.length,
                listed: app.event?.isListed,
                isLive: app.event?.isLive,
                protected: app.event?.isProtected,
                isFree: !app.components.eventDetails.refund.isPaidEvent,
                isPaid: app.components.eventDetails.refund.isPaidEvent,
                isDonation: app.event?.hasDonationTicketsAvailable,
                salesStatus: app.event?.salesStatus.sales_status,
                isExternalTicketAvailable:
                    app.components?.conversionBar.externalTicketsAreAvailable,
                shouldShowRemindMeButton:
                    app.components?.conversionBar.shouldShowRemindMeButton,
                ...disqualificationProperties,
                venueCountry: app.event?.venue?.country,
                venueRegion:
                    app.event?.venue?.region !== ''
                        ? app.event?.venue?.region
                        : undefined,
                timeZone: app.event?.start?.timezone,
                hasHeroVideo: !!app.components?.eventHero?.featuredVideo?.url,
                hasHeroVideoVertical: isYTShortVerticalVideo(
                    app.components?.eventHero?.featuredVideo?.url,
                ),
                hasImages: app.components?.eventHero?.items?.length,
                ticketTypesCount:
                    app.event_listing_response?.tickets?.ticketClasses?.length,
            },
        },
        options: { ...options },
    });
};
