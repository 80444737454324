import { gettext } from '@eventbrite/i18n';
import { Button, CalendarOffIcon, InlineAlert } from '@eventbrite/marmalade';
import classNames from 'classnames';
import React from 'react';

export type EnhancedExpiredBadgeProps = {
    onViewDetailsClick?: () => void;
    isEventDetailsPage: boolean;
    salesMessage: string;
};
export const EnhancedExpiredEventsBadge: React.FunctionComponent<
    EnhancedExpiredBadgeProps
> = ({ onViewDetailsClick, isEventDetailsPage, salesMessage }) => {
    const enhancedExpiredEventsBadgeClasses = classNames(
        'enhanced-expired-badge',
        {
            'enhanced-expired-badge--long-width': isEventDetailsPage,
        },
    );

    return (
        <>
            {isEventDetailsPage ? (
                <InlineAlert
                    className={enhancedExpiredEventsBadgeClasses}
                    variant="warning"
                    description={gettext('%(salesMessage)s', { salesMessage })}
                    icon={
                        <CalendarOffIcon
                            color="#3A3247"
                            className="enhanced-expired-badge__icon"
                        />
                    }
                ></InlineAlert>
            ) : (
                <InlineAlert
                    className="enhanced-expired-badge"
                    variant="warning"
                    description={gettext('%(salesMessage)s', { salesMessage })}
                    icon={
                        <CalendarOffIcon
                            color="#3A3247"
                            className="enhanced-expired-badge__icon"
                        />
                    }
                    cta={
                        <Button variant="ghost" onClick={onViewDetailsClick}>
                            {gettext('View event')}
                        </Button>
                    }
                ></InlineAlert>
            )}
        </>
    );
};
