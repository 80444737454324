import { FormattedSubCategory } from '@eventbrite/categories';
import { gettext } from '@eventbrite/i18n';
import { logEvent } from '@eventbrite/statsig';
import React, { useContext, useRef } from 'react';
import { STATSIG_EVENT_NAMES } from '../../experimentation';
import { IsTouchEnabledContext } from '../ScrollableDrawer/ScrollableDrawer';
import { BrowseCard } from './BrowseCard';
import './BrowseSubCategories.scss';

interface BrowseSubCategoriesProps {
    category: string;
    subcategories: FormattedSubCategory[];
    isMobile?: boolean;
    categoryRefinement: string;
}

const DEFAULT_SINGLE_ROW_HEIGHT = 200;
const DEFAULT_DOUBLE_ROW_HEIGHT = 310;
const TITLE_LONG_LINE_EXTRA_HEIGHT = 32;

export const BrowseSubCategories = ({
    category,
    subcategories,
    isMobile,
    categoryRefinement,
}: BrowseSubCategoriesProps) => {
    const divRef = useRef(null);

    const SCREEN_HEIGHT = window.innerHeight;
    let INITIAL_DRAWER_HEIGHT =
        SCREEN_HEIGHT < 650
            ? DEFAULT_SINGLE_ROW_HEIGHT
            : DEFAULT_DOUBLE_ROW_HEIGHT;

    // If the "Browse other {category} events" goes upto two lines, add 32px to the max height
    if (category.length > 8) {
        INITIAL_DRAWER_HEIGHT =
            INITIAL_DRAWER_HEIGHT + TITLE_LONG_LINE_EXTRA_HEIGHT;
    }
    const headerHeight = document.querySelector(
        '#event-page > div.drawer > div > div.header',
    )?.clientHeight;

    const { drawerState, setDrawerState, setIsTouchEnabled } = useContext(
        IsTouchEnabledContext,
    );

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        e.preventDefault();

        if (divRef.current) {
            const currentScrollY = (divRef.current as HTMLDivElement).scrollTop;
            const isScrollBarOnTop = currentScrollY == 0;

            //  Enable swipe actions if the scroll bar is on top (isScrollBarOnTop is true)
            if (isScrollBarOnTop) {
                setIsTouchEnabled(true);
            }
            // When scrolling down to view more categories, the drawer's height is fixed to 'full'
            else {
                setDrawerState('full');
                setIsTouchEnabled(false);
            }
        }
    };

    const categoryLowerCase = category.toLowerCase();

    const component = (
        <div className="browseSubCategories">
            <div className="header">{gettext('This event has ended')}</div>
            <div
                className="body"
                ref={divRef}
                style={{
                    maxHeight:
                        isMobile && drawerState === 'half'
                            ? `${INITIAL_DRAWER_HEIGHT}px`
                            : `calc(${SCREEN_HEIGHT}px - ${headerHeight}px - 20px)`,
                }}
                onScroll={handleScroll}
            >
                <h1 className="title">
                    {gettext('Browse other %(categoryLowerCase)s events', {
                        categoryLowerCase,
                    })}
                </h1>
                <div className="subcategories">
                    {Object.values(subcategories).map((subcat, index) => (
                        <a
                            href={`/b/local/${categoryRefinement}/${subcat.refinement}/`}
                            key={index}
                            onClick={() => {
                                logEvent(
                                    STATSIG_EVENT_NAMES.LISTING_SUBCATEGORY_CTA_CLICK,
                                );
                            }}
                        >
                            <BrowseCard
                                title={subcat.title}
                                imageUrl={
                                    subcat.imageUrlSmall || subcat.imageUrl
                                }
                            />
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );

    return component;
};
