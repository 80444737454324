import { sdkRequest } from '@eventbrite/http';
import type { TicketContextType } from './types';

export const fetchDataAndUpdateTicketData =
    (
        setIsFetchComplete: (isComplete: boolean) => void,
        setTicketData: (ticketData: TicketContextType) => void,
        ticketData: TicketContextType,
    ) =>
    async (eventID: string) => {
        setIsFetchComplete(false);
        try {
            const response = await fetchTicketExpressCheckoutInfo(eventID);
            const expressCheckoutResponse = {
                compactCheckoutDisqualifications: {
                    ...response?.compact_checkout_disqualifications,
                    // we cannot overwrite the 'has_discounts' property because
                    // the endpoint '/compact_checkout_details' consistently returns false for this key
                    // so we need to retain the original value, which must remain unchanged across events within the same series
                    has_discounts:
                        ticketData.compactCheckoutDisqualifications
                            .has_discounts,
                },
                panelDisplayPrice: response?.panel_display_price,
                ticketClasses: ticketData.ticketClasses,
            };
            setTicketData(expressCheckoutResponse);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsFetchComplete(true);
        }
    };

const fetchTicketExpressCheckoutInfo = async (eventID: string) => {
    const apiUrl = `/child_event/${eventID}/compact_checkout_details`;

    try {
        const response = await sdkRequest(apiUrl);
        if (response) return response;
        return null;
    } catch (error) {
        console.error('Error while doing API call:', error);
        throw error;
    }
};
