import { Icon } from '@eventbrite/eds-icon';
import { gettext } from '@eventbrite/i18n';
import classNames from 'classnames';
import React from 'react';
import { Fire } from './icons/Fire';
import { Megaphone } from './icons/Megaphone';
import { RebrandingGoingFast } from './icons/RebrandingGoingFast';
import { useUrgencyMessages } from './provider';
import { UrgencyMessageType } from './types';
import './UrgencyMessages.scss';

export type UrgencyMesssagesProps = {
    type: UrgencyMessagesTypes;
    className?: string;
    isAppRebrandActive?: boolean;
};

export enum UrgencyMessagesTypes {
    hurry = 'hurry',
    info = 'info',
}

export const UrgencyMessage: React.FC<UrgencyMesssagesProps> = ({
    children,
    type,
    className,
}) => (
    <div
        className={classNames(
            `urgency-message urgency-message--${type}`,
            className,
        )}
    >
        {children}
    </div>
);
export const UrgencyMessageHurry: React.FC<{ className?: string }> = ({
    children,
    className,
}) => (
    <UrgencyMessage type={UrgencyMessagesTypes.hurry} className={className}>
        <Icon type={<Fire />} size="xsmall" />
        {children}
    </UrgencyMessage>
);

export const FewTicketsLeft = ({
    className,
    isAppRebrandActive,
}: {
    className?: string;
    isAppRebrandActive?: boolean;
}) =>
    isAppRebrandActive ? (
        <UrgencyMessage type={UrgencyMessagesTypes.info} className={className}>
            {gettext('Few tickets left')}
        </UrgencyMessage>
    ) : (
        <UrgencyMessageHurry className={className}>
            {gettext('Few tickets left')}
        </UrgencyMessageHurry>
    );
export const GoingFast = ({
    className,
    isAppRebrandActive,
}: {
    className?: string;
    isAppRebrandActive?: boolean;
}) =>
    isAppRebrandActive ? (
        <UrgencyMessage type={UrgencyMessagesTypes.info} className={className}>
            <Icon type={<RebrandingGoingFast />} size="xsmall" />
            {gettext('Going fast')}
        </UrgencyMessage>
    ) : (
        <UrgencyMessageHurry className={className}>
            {gettext('Going fast')}
        </UrgencyMessageHurry>
    );
export const TicketSalesEndSoon = ({
    className,
    isAppRebrandActive,
}: {
    className?: string;
    isAppRebrandActive?: boolean;
}) =>
    isAppRebrandActive ? (
        <UrgencyMessage type={UrgencyMessagesTypes.info} className={className}>
            {gettext('Sales end soon')}
        </UrgencyMessage>
    ) : (
        <UrgencyMessage type={UrgencyMessagesTypes.info} className={className}>
            <Icon type={<Megaphone />} size="small" />

            {gettext('Ticket sales end soon')}
        </UrgencyMessage>
    );
export const UrgencyMessages: React.FC<{
    className?: string;
    isAppRebrandActive?: boolean;
}> = ({ className, isAppRebrandActive }) => {
    const values = useUrgencyMessages();

    return (
        <>
            {values[UrgencyMessageType.GoingFast] && (
                <GoingFast
                    className={classNames(className, {
                        'going-fast': isAppRebrandActive,
                    })}
                    isAppRebrandActive={isAppRebrandActive}
                />
            )}
            {values[UrgencyMessageType.FewTickets] && (
                <FewTicketsLeft
                    className={classNames(className, {
                        'few-tickets': isAppRebrandActive,
                    })}
                    isAppRebrandActive={isAppRebrandActive}
                />
            )}
            {values[UrgencyMessageType.SalesEndSoon] && (
                <TicketSalesEndSoon
                    className={classNames(className, {
                        'sales-end-soon': isAppRebrandActive,
                    })}
                    isAppRebrandActive={isAppRebrandActive}
                />
            )}
        </>
    );
};
