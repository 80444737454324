import { constants, formatFromAPI } from '@eventbrite/datetime-fns';
import { getAttendeeTimezone } from '@eventbrite/event-datetime';

import { getWindowObject } from '@eventbrite/feature-detection';
import {
    getNavigatorLastRecentViewsList,
    VIEWS_KEY,
} from '@eventbrite/personalization';
import {
    UrgencyCategoryKeys,
    UrgencyMessageKeys,
    UrgencySignalsProps,
} from '@eventbrite/urgency-signals';
import isEmpty from 'lodash/isEmpty';
import { Discount } from '../../../organizer/promotions/src/api/discounts';
import type {
    EBEvent,
    EventListingsResponse,
    ServerEvent,
    TimedEntryEventProps,
} from './types';
import { ChildEventProps, DiscountType, SALES_STATUS } from './types';

export const eventIsOnSale = (event: ChildEventProps) =>
    (event?.is_sales_ended === false && event?.is_sold_out === false) ||
    (event &&
        'sales_status' in event &&
        event?.sales_status === SALES_STATUS.ON_SALE);

export const eventIsNotYetOnSale = (event: ChildEventProps) =>
    (event?.is_sales_ended === false && event?.is_sold_out === false) ||
    (event &&
        'sales_status' in event &&
        event?.sales_status === SALES_STATUS.NOT_YET_ON_SALE);

export const eventIsSalesEnded = (event: ChildEventProps) =>
    (event && 'is_sales_ended' in event && event?.is_sales_ended == true) ||
    (event &&
        'sales_status' in event &&
        event?.sales_status === SALES_STATUS.SALES_ENDED);

export const eventIsSoldOut = (event: ChildEventProps) =>
    (event && 'is_sold_out' in event && event?.is_sold_out == true) ||
    (event &&
        'sales_status' in event &&
        event?.sales_status === SALES_STATUS.SOLD_OUT);
export const getSalesStatus = (event: ChildEventProps) => {
    if (event?.sales_status) {
        return event.sales_status;
    } else {
        return eventIsOnSale(event)
            ? SALES_STATUS.ON_SALE
            : SALES_STATUS.DEFAULT;
    }
};

export const getPurchasableEvents = (childEvents: ChildEventProps[] | null) => {
    return childEvents?.filter(eventIsOnSale);
};

export const findFirstPurchasableEvent = (
    childEvents: ChildEventProps[] | null,
) => {
    if (!childEvents) return null;
    const availableEvents: ChildEventProps[] =
        childEvents.filter(eventIsOnSale);
    return availableEvents[0] ?? null;
};

export const getEventChildById = (
    childEvents: ChildEventProps[] | null,
    eventId: string,
) => childEvents?.find((event) => event.id === eventId) || {};

export const isYTShortVerticalVideo = (url?: string) => {
    return url && isYoutubeURL(url) && url.includes('short');
};

export const isYTHorizontalVideoURL = (url: string) => {
    return isYoutubeURL(url) && !url.includes('short');
};

const isYoutubeURL = (url: string) => {
    const pattern = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;
    return pattern.test(url);
};

export const updateRecentViews = (event: ServerEvent) => {
    const eventsToStore: string[] = [
        event.id,
        ...getNavigatorLastRecentViewsList(9),
    ];
    const dedupedEventsToStore = [...new Set(eventsToStore)];
    localStorage.setItem(VIEWS_KEY, dedupedEventsToStore.toString());
};

const transformSessionToEventProps = (
    session: TimedEntryEventProps,
    parentEventId: string,
    locale: string,
    eventTimezone?: string,
    isOnline?: boolean,
    availability?: string,
): ChildEventProps => {
    const attendeeTimezone =
        getAttendeeTimezone() || constants.DEFAULT_TIMEZONE;
    const timezone = isOnline ? attendeeTimezone : eventTimezone;
    const startLocalTime = formatFromAPI(session.startDatetime, timezone);
    const endLocalTime = formatFromAPI(session.endDatetime, timezone);
    const location = getWindowObject('location');
    const newUrl = location?.href.replace(parentEventId, session.id);

    return {
        id: session.id,
        sales_status: session.salesStatus ?? SALES_STATUS.DEFAULT,
        start: {
            utc: session.startDatetime,
            local: startLocalTime,
            timezone,
        },
        end: {
            utc: session.endDatetime,
            local: endLocalTime,
            timezone,
        },
        locale: locale,
        url: newUrl ?? '',
        availability: availability,
    } as ChildEventProps;
};

export const mapTimedEntryInitialEvents = (
    eventSessionResponse: Array<TimedEntryEventProps>,
    parentEventId: string,
    locale: string,
    eventTimezone?: string,
    isOnline?: boolean,
): { transformedEventSessions: ChildEventProps[] } => {
    const transformedEventSessions = eventSessionResponse?.map((session) =>
        transformSessionToEventProps(
            session,
            parentEventId,
            locale,
            eventTimezone,
            isOnline,
        ),
    );
    return { transformedEventSessions };
};

export const mapTimedEntryApiEventSessions = (
    eventSessionResponse: {
        date: string;
        sessionCount: number;
        availability: string;
        sessions: Array<TimedEntryEventProps>;
    }[],
    parentEventId: string,
    locale: string,
    eventTimezone?: string,
    isOnline?: boolean,
): { transformedEventSessions: ChildEventProps[] } => {
    const transformedEventSessions: ChildEventProps[] = [];
    eventSessionResponse?.forEach((eventObject) => {
        const { availability, sessions } = eventObject;
        sessions.forEach((session) => {
            transformedEventSessions.push(
                transformSessionToEventProps(
                    session,
                    parentEventId,
                    locale,
                    eventTimezone,
                    isOnline,
                    availability,
                ),
            );
        });
    });
    return { transformedEventSessions };
};

export function isPromoCodeActive(
    discount: Discount | undefined,
): discount is Discount {
    if (!discount) {
        return false;
    }

    const discountApplied = discount?.percentOff || discount?.amountOff;
    return (
        discount?.discountType === DiscountType.open &&
        !isEmpty(discountApplied)
    );
}

export function getUrgencySignalsForTracking(
    urgencySignals: UrgencySignalsProps,
): string[] {
    if (!urgencySignals) {
        return [];
    }
    const categories = (
        Object.keys(urgencySignals.categories) as Array<UrgencyCategoryKeys>
    ).filter((key) => urgencySignals.categories[key]);
    const messages = (
        Object.keys(urgencySignals.messages) as Array<UrgencyMessageKeys>
    ).filter((key) => urgencySignals.messages[key]);

    return [...categories, ...messages];
}

type IsRepeatingCheck = {
    event: Pick<ServerEvent, 'childEvents' | 'isSeriesParent'>;
    event_listing_response?: Pick<EventListingsResponse, 'eventSessions'>;
};

export const isRepeatingEvent = ({
    event,
    event_listing_response: eventListingResponse,
}: IsRepeatingCheck): boolean => {
    if (!event?.childEvents && !eventListingResponse?.eventSessions) {
        return false;
    }

    const hasOldChildEvents = (event?.childEvents || []).length > 0;
    const hasTEEventSessions =
        (eventListingResponse?.eventSessions || []).length > 0;

    return event.isSeriesParent || hasOldChildEvents || hasTEEventSessions;
};

type IsSoldOutCheck = {
    event: Pick<EBEvent, 'salesStatus'>;
    eventListingResponse: Pick<EventListingsResponse, 'eventSessions'>;
};

export const isSoldOutEvent = ({
    event,
    eventListingResponse,
}: IsSoldOutCheck) => {
    if (event.salesStatus.sales_status === SALES_STATUS.SOLD_OUT) {
        return true;
    }

    return eventListingResponse?.eventSessions?.every(
        (event: TimedEntryEventProps) =>
            event.salesStatus === SALES_STATUS.SOLD_OUT,
    );
};
