import React from 'react';

export const FooterLogo: React.FC = () => (
    <svg
        data-testid="footer-logo"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 265.7 27"
        xmlSpace="preserve"
    >
        <g id="Layer_4" data-name="Layer 4">
            <g>
                <g>
                    <path
                        className="cls-1"
                        d="M234.1,11.9h-2.3l1.2-3.3h2.4c0.1-0.2,0.2-0.3,0.3-0.5c1.2-3,4.1-5,7.3-4.9c3-0.1,5.9,0,8.9,0     c0.1,0.2,0,0.4-0.1,0.5c-1.6,4.4-3.1,8.9-4.7,13.3c-0.1,0.3-0.2,0.6-0.1,0.9c0.6,0.1,0.6,0.1,1.6-1.5h1.9     c-0.4,1.5-1.4,2.7-2.7,3.4c-1,0.5-2.2,0.6-3.4,0.5c-1.4,0-2.6-1.2-2.6-2.7c0-0.4,0.1-0.8,0.3-1.1c0.8-2.4,1.7-4.9,2.5-7.3     l1.3-3.9c-1.2-0.2-2.3-0.1-3.5,0.2c-0.3,0.1-0.5,0.3-0.6,0.5c-0.5,0.8-1,1.6-1.2,2.5h2.8l-1.2,3.3h-2.7c-0.2,0.6-0.4,1.2-0.6,1.8     c-0.9,2.6-1.6,5.2-2.8,7.7c-0.8,1.9-2.2,3.6-4.1,4.5c-1.8,0.9-3.9,1-5.8,0.4c-0.4-0.1-0.8-0.3-1.2-0.6c-1-0.6-1.3-1.9-0.7-2.9     c0.5-1,1.6-1.5,2.7-1.2c0.9,0.2,1.6,1.1,1.5,2.1c0,0.4-0.1,0.8,0.4,0.9s0.7-0.2,1-0.5c0.6-0.9,1-1.9,1.4-2.9c1.1-3,2-5.9,3.1-8.9     C234.1,12.1,234.1,12,234.1,11.9z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M255.5,22.5c0.1-3.2-0.5-6.4-1.7-9.3c-0.1-0.3-0.3-0.5-0.4-0.8c-0.5-0.7-1-0.7-1.5,0     c-0.2,0.3-0.4,0.5-0.5,0.8h-1.9c0.4-1.7,1.4-3.1,2.9-4.1c2-1.2,4-1.1,5.4,1c1,1.5,1.5,3.3,1.7,5.1c0.1,1.2,0.2,2.3,0.1,3.5     c1.2-1.5,2-3.2,2.5-5c-0.2-0.1-0.4-0.2-0.6-0.2c-1.3-0.3-2-1.6-1.7-2.9c0.1-0.3,0.2-0.5,0.3-0.7c0.7-1.2,2.1-1.7,3.5-1.3     c1.3,0.5,2,1.8,1.7,3.1c-0.6,4.3-2.8,7.6-5.9,10.5c-1.6,1.6-3.6,2.8-5.8,3.3c-1.3,0.4-2.7,0.3-3.9-0.2c-1.3-0.4-2.1-1.9-1.7-3.2     c0.1-0.2,0.1-0.3,0.2-0.5c0.4-0.8,1.3-1.3,2.2-1.2c0.9,0,1.7,0.6,1.9,1.5c0,0.2,0.1,0.4,0.1,0.6c0,0.7,0.2,0.9,0.9,1     C254,23.6,254.9,23.2,255.5,22.5z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M202.3,12.8l2.2-4.1h5.1l-3.4,5.1l3.6,6.1h-5.1l-2.4-4.9h-0.1v4.9H198V6.4c-0.1-1,0.6-2,1.6-2.3     c0.8-0.3,1.7-0.7,2.6-1v9.7L202.3,12.8z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M222.9,11.9h-1.4V8.6h1.4V6.5c-0.1-1.2,0.6-2.2,1.7-2.5l2.4-1v5.7h3.7l-1.2,3.2h-2.6c0,1.5,0,2.9,0,4.3     c0,0.4,0.3,0.7,0.7,0.7c0,0,0.1,0,0.1,0c0.4,0,0.8-0.1,1.2-0.1v3.2c-1.6,0.5-3.2,0.5-4.8,0c-0.8-0.3-1.3-1.1-1.3-1.9     c-0.1-1.2-0.2-2.4-0.1-3.6C222.9,13.6,222.9,12.8,222.9,11.9z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M177.8,8.7h2.8v3.2h-2.8c0,1.5,0,2.9,0,4.3c0,0.4,0.4,0.6,0.8,0.6s0.8-0.1,1.2-0.1v3.2     c-1.5,0.4-3.1,0.5-4.7,0c-1-0.4-1.7-1.4-1.6-2.4c-0.1-1.6,0-3.2,0-4.9v-0.8h-1.4V8.7h1.4c0-0.9,0-1.7,0-2.5     c-0.1-0.8,0.4-1.6,1.1-1.9c0.9-0.5,1.9-0.8,3-1.2L177.8,8.7z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M196.3,8.7l-0.4,3.5c-1.2-0.2-2.4-0.6-3.1,0.8c-0.6,1-0.5,2.2,0.2,3.1c0.8,1,1.9,0.6,2.9,0.4l0.3,3.4     c-0.4,0.2-0.8,0.3-1.2,0.3c-1.1,0.2-2.2,0.2-3.3,0c-1.6-0.3-3-1.5-3.4-3.1c-0.5-1.6-0.4-3.2,0.1-4.7c0.7-2.2,2.6-3.7,4.9-3.8     C194.4,8.4,195.4,8.4,196.3,8.7z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M186.5,19.9h-4.1V8.7h4.1V19.9z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M187,5.4c0,1.4-1.1,2.5-2.5,2.5S182,6.8,182,5.4c0.1-1.3,1.2-2.4,2.5-2.4C185.8,3,186.9,4.1,187,5.4     C187,5.4,187,5.4,187,5.4z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M215.7,8.4c-2.7-0.2-5.2,1.6-5.7,4.3c-0.2,1.1-0.3,2.2-0.1,3.2c0.2,2.1,1.8,3.8,3.9,4.1     c1.2,0.2,2.5,0.2,3.8,0c0.9-0.1,1.8-0.3,2.6-0.7c-0.1-1-0.2-2-0.4-3l-0.7,0.2c-1.2,0.4-2.5,0.5-3.8,0.3c-0.7-0.1-1.2-0.6-1.2-1.3     h6.4c0-0.5,0.1-0.9,0.1-1.4C220.7,11.2,219.4,8.5,215.7,8.4z M214,12.9c-0.1-0.8,0.5-1.5,1.2-1.6c0.1,0,0.1,0,0.2,0     c0.8,0,1.2,0.6,1.1,1.6H214z"
                    ></path>
                </g>
                <g>
                    <path
                        className="cls-1"
                        d="M98.9,20v-9.6h-2V7.2h2V2.7l3.4-0.8v5.3h2.6v3.2h-2.6V20H98.9z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M62.5,20L57.4,7.2H61l3.2,8.7l3.3-8.7h3.6L66,20H62.5z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M106.1,20V1.1l3.3-0.8v7.8c1-0.8,2.3-1.2,3.6-1.2c3.7,0,6.7,3,6.7,6.7s-3,6.7-6.7,6.7c0,0,0,0,0,0     c-1.3,0-2.5-0.4-3.6-1.2V20H106.1z M109.3,13.8c0.1,2,1.8,3.5,3.8,3.4s3.5-1.8,3.4-3.8c-0.1-1.9-1.7-3.4-3.6-3.4     c-1.9,0-3.5,1.5-3.6,3.4V13.8z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M128.4,2.7c0-1.2,0.9-2.2,2.1-2.2c0,0,0.1,0,0.1,0c1.2,0,2.2,0.9,2.2,2.1c0,0,0,0.1,0,0.1     c0,1.2-0.9,2.2-2.1,2.2c0,0,0,0-0.1,0C129.4,4.9,128.4,4,128.4,2.7C128.4,2.7,128.4,2.7,128.4,2.7z M128.9,20V7.2h3.3V20H128.9z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M127.8,7.2c-1.3,0-2.6,0.3-3.7,1.1V7.2h-3.3V20h3.3v-6.3c0-2,0.5-3.1,3.7-3.2L127.8,7.2z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M135.4,20v-9.6h-2V7.2h2V2.7h3.4v4.5h2.6v3.2h-2.6V20H135.4z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M79.7,15.6c-1.1,1.7-3.4,2.1-5,1c-0.4-0.3-0.7-0.6-1-1l6.5-1.5l1.1-0.2l2-0.5c0-0.4-0.1-0.9-0.2-1.3     c-0.8-3.6-4.4-5.9-8-5s-5.9,4.4-5,8s4.4,5.9,8,5c2-0.5,3.7-1.9,4.6-3.8L79.7,15.6z M75.9,10.1c1.4-0.3,2.9,0.2,3.8,1.4l-6.5,1.5     C73.3,11.6,74.4,10.4,75.9,10.1z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M151,15.6c-1.1,1.7-3.4,2.1-5,1c-0.4-0.3-0.7-0.6-1-1l6.6-1.5l1.1-0.2l2-0.5c-0.1-3.7-3.2-6.6-6.8-6.5     c-3.7,0.1-6.6,3.2-6.5,6.8c0.1,3.7,3.2,6.6,6.8,6.5c0.4,0,0.9-0.1,1.3-0.2c2-0.5,3.7-1.9,4.6-3.8L151,15.6z M147.2,10.1     c1.4-0.3,2.9,0.2,3.8,1.4l-6.5,1.5C144.7,11.6,145.8,10.4,147.2,10.1z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M54.8,15.6c-0.5,0.8-1.3,1.3-2.2,1.5c-1.4,0.3-3-0.2-3.8-1.5l6.5-1.5l1.1-0.2l2-0.5c0-0.4-0.1-0.9-0.2-1.3     c-0.8-3.6-4.4-5.9-8-5s-5.9,4.4-5,8s4.4,5.9,8,5c2.1-0.5,3.8-1.9,4.6-3.8L54.8,15.6z M51,10.1c1.4-0.3,2.9,0.2,3.8,1.4l-6.5,1.5     C48.5,11.6,49.6,10.4,51,10.1z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M93,20v-6.9c0-2.5-1.3-3-2.6-3s-2.6,0.5-2.6,3V20h-3.3V7.2h3.3v0.9c0.9-0.8,2.1-1.2,3.4-1.2     c2.8,0,5.2,1.6,5.2,5.7V20H93z"
                    ></path>
                </g>
                <path
                    className="cls-1"
                    d="M163.6,17.4c-0.3,0-0.5-0.2-0.5-0.5v-6.7c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5V17    C164.1,17.2,163.9,17.4,163.6,17.4z"
                ></path>
                <path
                    className="cls-1"
                    d="M167,14.1h-6.7c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5h6.7c0.3,0,0.5,0.2,0.5,0.5    C167.4,13.9,167.2,14.1,167,14.1C167,14.1,167,14.1,167,14.1z"
                ></path>
            </g>
        </g>
        <path
            className="cls-1"
            d="M14.2,16.5c-0.7,1.1-1.8,1.8-3.1,2.1c-2,0.5-4.2-0.3-5.4-2.1l9.3-2.1l1.5-0.4l2.8-0.7c0-0.6-0.1-1.3-0.2-1.9  C18,6.4,12.9,3.2,7.8,4.4s-8.3,6.2-7.1,11.4S6.9,24,12,22.9c2.9-0.7,5.3-2.6,6.6-5.4L14.2,16.5z M8.8,8.6c2-0.5,4.1,0.3,5.3,2  l-9.2,2.1C5.2,10.8,6.8,9.1,8.8,8.6z"
        ></path>
        <line className="cls-1" x1="31.1" y1="0.5" x2="31.1" y2="26.2"></line>
    </svg>
);
