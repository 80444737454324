import classNames from 'classnames';
import React, {
    AnimationEvent,
    FunctionComponent,
    useEffect,
    useState,
} from 'react';
import './stepper.scss';

export interface StepperTotalQuantityProps {
    animationClasses?: string;
    handleQuantityAnimationEnd?: (e: AnimationEvent<HTMLDivElement>) => void;
    isAppRebrandActive?: boolean;
    isDisabled?: boolean;
    minValue: number;
    shouldJump?: boolean;
    shouldFadeOut?: boolean;
    shouldShake?: boolean;
    totalQuantity: number;
    wrapperClasses?: string;
}

export const StepperTotalQuantity: FunctionComponent<
    StepperTotalQuantityProps
> = ({
    animationClasses,
    handleQuantityAnimationEnd,
    isAppRebrandActive = false,
    isDisabled = false,
    minValue,
    shouldJump = false,
    shouldFadeOut = false,
    shouldShake = false,
    totalQuantity,
    wrapperClasses,
}) => {
    const [totalQuantityToDisplay, setTotalQuantityToDisplay] =
        useState(minValue);

    useEffect(() => {
        // When the quantity is not at the minimum, we ensure the quantity displayed
        // is the real one and not tied to any delay from a fading animation
        if (!shouldFadeOut || totalQuantity > minValue) {
            setTotalQuantityToDisplay(totalQuantity);
        }
    }, [minValue, shouldFadeOut, totalQuantity]);

    const quantityClassName = classNames(
        'eds-stepper-total-quantity',
        animationClasses,
        {
            'eds-stepper-jumping-animation': shouldJump,
            'eds-fx--shake': shouldShake,
            'rebranding--active': isAppRebrandActive,
        },
    );

    const quantityWrapperClassName = classNames(
        { 'eds-text-bl eds-mar-hor-4': !isAppRebrandActive },
        'eds-stepper__quantity-label',
        {
            'eds-text-color--ui-500': isDisabled && !isAppRebrandActive,
            'eds-text-color--ui-900': !isDisabled && !isAppRebrandActive,
            'eds-stepper__quantity-label--disabled':
                isDisabled && isAppRebrandActive,
            'eds-stepper-fade-out': shouldFadeOut,
            'rebranding--active': isAppRebrandActive,
        },
        wrapperClasses,
    );

    return (
        <div
            className={quantityWrapperClassName}
            data-testid="eds-stepper-quantity-wrapper"
            onAnimationEnd={() => {
                // After the fading in animation is completed we update the displayed total
                // quantity to the real one
                setTotalQuantityToDisplay(totalQuantity);
            }}
        >
            <span
                className={quantityClassName}
                data-testid="eds-stepper-quantity"
                onAnimationEnd={handleQuantityAnimationEnd}
            >
                {totalQuantityToDisplay}
            </span>
        </div>
    );
};
