export enum CheckoutLifecycleEventPostMessageName {
    applePayPaymentCanceled = 'applePayPaymentCanceled',
    backToTicketSelection = 'backToTicketSelection',
    checkoutFormLoaded = 'checkoutFormLoaded',
    deliveryMethodFormLoaded = 'deliveryMethodFormLoaded',
    expressPaymentCanceled = 'expressPaymentCanceled',
    expressPaymentProcessingOrder = 'expressPaymentProcessingOrder',
    joinWaitlist = 'joinWaitlist',
    onlyCheckoutLoaded = 'onlyCheckoutLoaded',
    orderComplete = 'orderComplete',
    orderCreationError = 'orderCreationError',
    orderPlacingError = 'orderPlacingError',
    orderStart = 'orderStart',
    orderStatusCanceled = 'OrderStatusCanceled',
    orderStatusPlaced = 'OrderStatusPlaced',
    organizerQuestionsFormLoaded = 'organizerQuestionsFormLoaded',
    organizerQuestionsFormResize = 'organizerQuestionsFormResize',
    payInStatusPlaced = 'PayInStatusPlaced',
    protectCheckout = 'protectCheckout',
    ticketQuantityChange = 'ticketQuantityChange',
    ticketSelectionCompactLoaded = 'ticketSelectionCompactLoaded',
    ticketSelectionLayoutStabilized = 'ticketSelectionLayoutStabilized',
    ticketSelectionResize = 'ticketSelectionResize',
    ticketSelectionSubmit = 'ticketSelectionSubmit',
    widgetModalClose = 'widgetModalClose',
    initialPageFullyLoaded = 'initialPageFullyLoaded',
    widgetRenderComplete = 'widgetRenderComplete',
}
