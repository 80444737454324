import { GenericLazyString } from '@eventbrite/i18n';
import React from 'react';
import './BrowseSubCategories.scss';

interface CardContent {
    title: string | GenericLazyString;
    imageUrl: string;
    style?: 'horizontal' | 'vertical';
    imageLoading?: 'eager' | 'lazy';
}
export const BrowseCard: React.FunctionComponent<CardContent> = ({
    title,
    imageUrl,
    style = 'Vertical',
    imageLoading = 'eager',
}) => {
    return (
        <div className={`browseCard browseCard${style}`}>
            <div className="browseCardImageWrapper">
                <img
                    src={imageUrl}
                    alt={title?.toString()}
                    loading={imageLoading}
                />
            </div>
            <p className="browseCardTitle eds-text-weight--heavy">{title}</p>
        </div>
    );
};
