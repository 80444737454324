import { ErrorBoundary } from '@eventbrite/error-boundary';
import React, { useContext } from 'react';
import { LayoutContext } from './Layout';
import styles from './Layout.module.scss';

export enum Modules {
    aboutThisEvent = 'aboutThisEvent',
    agenda = 'agenda',
    aside = 'aside',
    banners = 'banners',
    creatorCollections = 'creatorCollections',
    dateAndTime = 'dateAndTime',
    enhancedExpiredEventsBadge = 'enhancedExpiredEventsBadge',
    enhancedExpiredEvents = 'enhancedExpiredEvents',
    eventHero = 'eventHero',
    faq = 'faq',
    highlights = 'highlights',
    location = 'location',
    mainContent = 'mainContent',
    musicEventInfo = 'musicEventInfo',
    notifications = 'notifications',
    organizerBrief = 'organizerBrief',
    organizerPanel = 'organizerPanel',
    performers = 'performers',
    refundPolicy = 'refundPolicy',
    salesEndedMessage = 'salesEndedMessage',
    startDate = 'startDate',
    summary = 'summary',
    tags = 'tags',
    tickets = 'tickets',
    title = 'title',
    urgencySignals = 'urgencySignals',
}

const hiddenModules = {
    exploreMoreEvents: [
        Modules.location,
        Modules.dateAndTime,
        Modules.performers,
        Modules.refundPolicy,
        Modules.agenda,
        Modules.aboutThisEvent,
        Modules.faq,
        Modules.banners,
        Modules.tags,
        Modules.organizerPanel,
    ],
};

const Fallback = () => <div data-testid="fallback"></div>;

export const LayoutModule: React.FC<{ name: Modules }> = ({
    children,
    name,
}) => {
    const layout = useContext(LayoutContext);

    if (
        layout?.exploreMoreEvents &&
        hiddenModules.exploreMoreEvents.includes(name)
    ) {
        return null;
    }

    return (
        <ErrorBoundary fallback={<Fallback />}>
            <div
                className={`${styles.module} ${styles[name]}`}
                data-testid={name}
            >
                {children}
            </div>
        </ErrorBoundary>
    );
};
