import React, { createContext, useContext, useState } from 'react';
import { fetchDataAndUpdateTicketData } from './helpers';
import type { TicketContextProviderProps, TicketContextType } from './types';

export const TicketContext = createContext<{
    ticketData: TicketContextType;
    totalTicketsSelected: number;
    setTicketData: React.Dispatch<React.SetStateAction<TicketContextType>>;
    fetchDataAndUpdateTicketData: (eventID: string) => void;
    setTotalTicketsSelected: (total: number) => void;
    isFetchComplete: boolean;
} | null>(null);
TicketContext.displayName = 'TicketContext';

export const useTicketContext = () => {
    const context = useContext(TicketContext);
    if (!context)
        throw new Error(
            'TicketContext has to be used within TicketContextProvider',
        );
    return context;
};

// TODO refactor this to use a Reducer
export const TicketContextProvider: React.FC<TicketContextProviderProps> = ({
    children,
    ticketServerData,
}) => {
    const [ticketData, setTicketData] = useState(ticketServerData);
    const [totalTicketsSelected, setTotalTicketsSelected] = useState(0);
    const [isFetchComplete, setIsFetchComplete] = useState(true);

    return (
        <TicketContext.Provider
            value={{
                ticketData,
                totalTicketsSelected,
                setTicketData,
                fetchDataAndUpdateTicketData: fetchDataAndUpdateTicketData(
                    setIsFetchComplete,
                    setTicketData,
                    ticketData,
                ),
                setTotalTicketsSelected,
                isFetchComplete,
            }}
        >
            {children}
        </TicketContext.Provider>
    );
};
