import { DiscoveryEvent } from '@eventbrite/event-renderer';
import { gettext } from '@eventbrite/i18n';
import { InfoIcon, InlineAlert } from '@eventbrite/marmalade';
import { getExperiment } from '@eventbrite/statsig';
import React, { useEffect, useState } from 'react';
import { LISTING_HEAP_PAGE_AREA_IDS } from '../../constants';
import { LISTING_SUBCATEGORY_BROWSE } from '../../experimentation';
import { AffiliateCodes } from '../../types';
import { BrowseSubCategoriesContainer } from '../BrowseSubCategories/BrowseSubCategoriesContainer';
import { ShareProps } from '../ShareEvent/ShareEventRoot';
import { SimplifiedOrganizerInfo } from '../_shared/OrganizerInfo/SimplifiedOrganizerInfo';
import { StyleTypes } from '../_shared/OrganizerStats/OrganizerStats';
import { EnhancedExpiredEventsBadge } from './EnhancedExpiredEventsBadge';
import { EnhancedExpiredEventsList } from './EnhancedExpiredEventsList';
import './ExpiredEvents.scss';
import { ExpiredEventsList } from './ExpiredEventsList';
import { usePromotedEvents } from './usePromotedEvents';

export type ExpiredEventsPageProps = {
    relatedEvents: DiscoveryEvent[];
    eventName: string;
    category?: string;
    organizer: {
        name: string;
        profilePicture?: string;
        id: string;
    };
    isAuthenticated: boolean;
    shareComponentProps: ShareProps;
    onViewDetailsClick: () => void;
    locale: string;
    isSalesEnded: boolean;
    isSoldOut: boolean;
    salesMessage: string;
    isMobile: boolean;
};

export const ExpiredEventsPage = ({
    relatedEvents,
    eventName,
    category,
    organizer,
    isAuthenticated,
    shareComponentProps,
    onViewDetailsClick,
    locale,
    isSalesEnded,
    isSoldOut,
    isMobile,
    salesMessage,
}: ExpiredEventsPageProps) => {
    const {
        name: organizerName,
        profilePicture: organizerAvatar,
        id: organizerId,
    } = organizer;

    const { promotedEvents } = usePromotedEvents();

    const [showSubcategoryBrowseModal, setShowSubcategoryBrowseModal] =
        useState<null | boolean>(null);

    useEffect(() => {
        const experiment = getExperiment(
            LISTING_SUBCATEGORY_BROWSE.experimentName,
            LISTING_SUBCATEGORY_BROWSE.param,
            LISTING_SUBCATEGORY_BROWSE.defaultValue,
        );
        setShowSubcategoryBrowseModal(experiment == 'show');
    }, []);

    const eventsListWrapper =
        isSalesEnded || isSoldOut ? (
            <EnhancedExpiredEventsList
                isAuthenticated={isAuthenticated}
                locale={locale}
                affCode={AffiliateCodes.AFF_RELATED_EVENTS_EXPIRED}
                organizer={organizer}
            />
        ) : (
            <ExpiredEventsList
                relatedEvents={relatedEvents}
                promotedEvents={promotedEvents}
                isAuthenticated={isAuthenticated}
                shareComponentProps={shareComponentProps}
                areMetricsEnabled={isSalesEnded || isSoldOut}
            />
        );
    const browseSubCategoriesExperimentProps = {
        category,
        isMobile,
    };

    const maintenanceStartTime = Date.UTC(2024, 9, 20, 7, 15); // Oct 20, 2024, 07:15 AM in UTC (Oct 20, 2024, 12:15 AM PDT) (Oct 20, 2024, 09:15 AM CEST)
    const maintenanceEndTime = Date.UTC(2024, 9, 21, 7, 15); // Oct 21, 2024, 07:15 AM in UTC (Oct 21, 2024, 12:15 AM PDT) (Oct 21, 2024, 09:15 AM CEST)
    const now = Date.now();
    const shouldShowMaintenanceBanner =
        now >= maintenanceStartTime && now <= maintenanceEndTime;
    const maintenanceBannerMessage = (
        <>
            {gettext(
                'We’ll be performing routine site maintenance starting at 12:15 AM PDT on Monday October 21. We expect this to take 15 minutes, and eventbrite.com will be unavailable during that time. For the latest status updates, refer to ',
            )}
            <a
                href="http://www.eventbritestatus.com"
                target="_blank"
                rel="noreferrer"
            >
                http://www.eventbritestatus.com
            </a>
            .
        </>
    );
    const MaintenanceBanner = () => {
        return (
            <InlineAlert
                className={'maintenance-banner'}
                variant="opportunity"
                description={maintenanceBannerMessage}
                icon={<InfoIcon />}
            />
        );
    };

    return (
        <div className="expired-event">
            <div className="expired-wrapper">
                {showSubcategoryBrowseModal === true && (
                    <BrowseSubCategoriesContainer
                        {...browseSubCategoriesExperimentProps}
                    />
                )}
                <div className="expired-events__container">
                    {shouldShowMaintenanceBanner && <MaintenanceBanner />}
                    {isSalesEnded || isSoldOut ? (
                        <EnhancedExpiredEventsBadge
                            onViewDetailsClick={onViewDetailsClick}
                            isEventDetailsPage={false}
                            salesMessage={salesMessage}
                        />
                    ) : (
                        <span className="expired-badge">
                            {gettext('Event ended')}
                        </span>
                    )}

                    <div className="expired-info">
                        <h1 className="expired-heading">{eventName}</h1>
                    </div>
                    {!isSalesEnded || !isSoldOut ? (
                        <button
                            className="expired-view-details"
                            onClick={onViewDetailsClick}
                        >
                            {gettext('View details')}
                        </button>
                    ) : null}
                    <hr className="expired-divider" />
                    <div>
                        <h2 className="expired-organizer-intro">
                            {gettext(
                                'Follow this organizer to stay informed on future events',
                            )}
                        </h2>
                        <div className="expired-organizer">
                            <SimplifiedOrganizerInfo
                                organizerName={organizerName}
                                organizerAvatar={organizerAvatar}
                                organizerId={organizerId}
                                style={StyleTypes.CONDENSED}
                                pageArea={
                                    LISTING_HEAP_PAGE_AREA_IDS.EXPIRED_EVENTS_PAGE
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="events-list-wrapper">{eventsListWrapper}</div>
        </div>
    );
};
