import { HAS_WINDOW_LISTENERS } from '@eventbrite/feature-detection';
import { gettext } from '@eventbrite/i18n';
import { TicketCard } from '@eventbrite/listings-multitickets';
import { Stack } from '@eventbrite/marmalade';
import { logEvent } from '@eventbrite/statsig';
import React, { useEffect, useState } from 'react';
import { LISTING_HEAP_IDS } from '../../constants';
import {
    useEventBasicInformationContext,
    useScrollOnCommand,
    useTicketContext,
} from '../../contexts';
import { useCommunicationDispatch } from '../../contexts/CommunicationContext';
import { getCheckoutType, STATSIG_EVENT_NAMES } from '../../experimentation';
import { CheckoutType } from '../../types';
import { EventDetailsSectionTitle } from '../EventDetails/EventDetails';
import {
    CheckoutPostMessagesNames,
    useIncomingCheckoutMessages,
} from '../hooks/useIncomingCheckoutMessages';
import { useTrackComponent } from '../hooks/useTrackComponent';
import { qualifiesForMultiticketSelection } from './multiTicketsSelection';
import { TicketClass } from './types';
import { useTicketQuantityStore } from './useTicketQuantityStore';

const setWindowListeners = (handlers: (event: MessageEvent) => void) => {
    HAS_WINDOW_LISTENERS && window.addEventListener('message', handlers);

    return () => {
        HAS_WINDOW_LISTENERS && window.removeEventListener('message', handlers);
    };
};

export const Tickets = () => {
    const { ticketData, setTotalTicketsSelected } = useTicketContext();
    const { childEvents, salesStatus } = useEventBasicInformationContext();
    const { sendNewQuantities } = useCommunicationDispatch();

    const ticketRef = React.useRef<HTMLDivElement>(null);
    useScrollOnCommand(ticketRef, 'tickets');

    const [resendTicketData, setResendTicketData] = useState(false);

    const { ticketClasses, compactCheckoutDisqualifications } = ticketData;

    const {
        ticketQuantities,
        changeQuantity,
        refreshQuantities,
        resetQuantities,
    } = useTicketQuantityStore(
        ticketClasses,
        sendNewQuantities,
        setTotalTicketsSelected,
    );

    const { ticketsInfo: { hasBogoTickets } = {} } =
        useEventBasicInformationContext();

    const handlers = useIncomingCheckoutMessages({
        handlers: {
            [CheckoutPostMessagesNames.onlyCheckoutLoaded]: () =>
                setResendTicketData(true),
            [CheckoutPostMessagesNames.orderCreationError]: () =>
                setResendTicketData(true),
            [CheckoutPostMessagesNames.orderComplete]: () => resetQuantities(),
        },
    });

    useEffect(
        () => setWindowListeners(handlers),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    useEffect(() => {
        if (resendTicketData) {
            refreshQuantities();
            setResendTicketData(false);
        }
    }, [resendTicketData, refreshQuantities]);

    const isMultiTicketEnabled = qualifiesForMultiticketSelection(
        {
            compactCheckoutDisqualifications,
            childEvents,
            salesStatus,
        },
        ticketClasses,
        hasBogoTickets,
    );

    useTrackComponent({
        ref: ticketRef,
        heap: {
            eventName: LISTING_HEAP_IDS.TICKET_SECTION_VIEW,
            eventBucketLabel:
                'Views on the Ticket section (top of the section)',
        },
        statsig: {
            eventName:
                STATSIG_EVENT_NAMES.LISTING_MULTITICKET_TICKET_SECTION_VIEW,
        },
    });

    if (
        getCheckoutType(
            isMultiTicketEnabled,
            compactCheckoutDisqualifications,
        ) === CheckoutType.Compact
    ) {
        return null;
    }

    return (
        <section
            className="event-details__section --padding-top"
            ref={ticketRef}
            id="tickets"
        >
            <EventDetailsSectionTitle>
                <h2>{gettext('Tickets')}</h2>
            </EventDetailsSectionTitle>
            <Stack space="spacing-lg" as="ul">
                {ticketClasses.map((ticket: TicketClass) => {
                    return (
                        <li key={ticket.id}>
                            <TicketCard
                                characteristics={ticket.characteristics}
                                onQuantityChange={(ticketClassId, quantity) => {
                                    logEvent(
                                        STATSIG_EVENT_NAMES.LISTING_MULTITICKET_TICKET_SELECTED_CLICK,
                                    );
                                    changeQuantity(ticketClassId, quantity);
                                }}
                                cost={ticket.cost}
                                total={ticket.totalCost}
                                allInclusivePrice={
                                    ticket.variants[0]?.useAllInPrice
                                }
                                description={ticket.description}
                                displayFlags={ticket.displayFlags}
                                fee={ticket.fee}
                                id={ticket.id}
                                name={ticket.displayName}
                                tax={ticket.tax}
                                quantityRemaining={ticket.quantityRemaining}
                                maximumQuantityPerOrder={
                                    ticket.maximumQuantityPerOrder
                                }
                                minimumQuantity={ticket.minimumQuantity}
                                onSaleStatus={ticket.onSaleStatus}
                                onSaleStatusEnum={ticket.onSaleStatusEnum}
                                quantity={ticketQuantities[ticket.id]}
                            />
                        </li>
                    );
                })}
            </Stack>
        </section>
    );
};
