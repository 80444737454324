import { getAttendeeTimezone } from '@eventbrite/event-datetime';
import { useCallback, useState } from 'react';
import {
    EventActionType,
    useEventBasicInformationContext,
    useEventDispatchContext,
} from '../../../../contexts';
import { mapTimedEntryApiEventSessions } from '../../../../utils';
import { getTimedEntryCalendarData } from '../../api';
import { getMonthNameAndYear } from './utils';

export const useTimedEntryCalendarData = () => {
    const dispatch = useEventDispatchContext();
    const {
        parentEventId,
        id,
        locale,
        start,
        place: { isOnline },
    } = useEventBasicInformationContext();
    const [loading, setLoading] = useState<boolean>(false);

    const fetchTimedEntryCalendarData = useCallback(
        async (startDateTime: string) => {
            const { year, month } = getMonthNameAndYear(startDateTime);

            const timezone = isOnline ? getAttendeeTimezone() : start?.timezone;

            setLoading(true);

            try {
                const response = await getTimedEntryCalendarData(
                    parentEventId,
                    year,
                    month,
                    timezone,
                );
                const sessionsData = response?.dates.map((date) => date);
                const { transformedEventSessions } =
                    mapTimedEntryApiEventSessions(
                        sessionsData,
                        id,
                        locale,
                        start?.timezone,
                        isOnline,
                    );
                dispatch({
                    type: EventActionType.updateChildEventForTimedEntry,
                    payload: {
                        timedEntryEvents: transformedEventSessions,
                    },
                });
            } catch (error) {
                console.error('Error while fetching timed entry data', error);
            }
            setLoading(false);
        },
        [parentEventId, id, locale, dispatch, start, isOnline],
    );

    return {
        fetchTimedEntryCalendarData,
        loading,
    };
};
