import { GenericLazyString, gettext } from '@eventbrite/i18n';
import accessories from '../static/images/subcategories/accessories.webp';
import acoustic from '../static/images/subcategories/acoustic.webp';
import adult from '../static/images/subcategories/adult.webp';
import aetheism from '../static/images/subcategories/aetheism.webp';
import after_school_care from '../static/images/subcategories/after_school_care.webp';
import agnostic from '../static/images/subcategories/agnostic.webp';
import airshow from '../static/images/subcategories/airshow.webp';
import alternative_music from '../static/images/subcategories/alternative_music.webp';
import alumni from '../static/images/subcategories/alumni.webp';
import americana_music from '../static/images/subcategories/americana_music.webp';
import animalwelfare from '../static/images/subcategories/animalwelfare.webp';
import anime from '../static/images/subcategories/anime.webp';
import auto from '../static/images/subcategories/auto.webp';
import baby from '../static/images/subcategories/baby.webp';
import ballet from '../static/images/subcategories/ballet.webp';
import baseball from '../static/images/subcategories/baseball.webp';
import basketball from '../static/images/subcategories/basketball.webp';
import beauty from '../static/images/subcategories/beauty.webp';
import beer from '../static/images/subcategories/beer.webp';
import bluegrass from '../static/images/subcategories/bluegrass.webp';
import blues from '../static/images/subcategories/blues.webp';
import blues_and_jazz from '../static/images/subcategories/blues_and_jazz.webp';
import boat from '../static/images/subcategories/boat.webp';
import books from '../static/images/subcategories/books.webp';
import bridal from '../static/images/subcategories/bridal.webp';
import buddhism from '../static/images/subcategories/buddhism.webp';
import camp from '../static/images/subcategories/camp.webp';
import canoeing from '../static/images/subcategories/canoeing.webp';
import career from '../static/images/subcategories/career.webp';
import cheer from '../static/images/subcategories/cheer.webp';
import childrenandyouth from '../static/images/subcategories/childrenandyouth.webp';
import christianity from '../static/images/subcategories/christianity.webp';
import christmas from '../static/images/subcategories/christmas.webp';
import city_town from '../static/images/subcategories/city_town.webp';
import classical_music from '../static/images/subcategories/classical_music.webp';
import climbing from '../static/images/subcategories/climbing.webp';
import comedy from '../static/images/subcategories/comedy.webp';
import comics from '../static/images/subcategories/comics.webp';
import country_music from '../static/images/subcategories/country_music.webp';
import county from '../static/images/subcategories/county.webp';
import county_government from '../static/images/subcategories/county_government.webp';
import craft from '../static/images/subcategories/craft.webp';
import cultural_music from '../static/images/subcategories/cultural_music.webp';
import cycling from '../static/images/subcategories/cycling.webp';
import dance from '../static/images/subcategories/dance.webp';
import dating from '../static/images/subcategories/dating.webp';
import democratic_party from '../static/images/subcategories/democratic_party.webp';
import design from '../static/images/subcategories/design.webp';
import dinner from '../static/images/subcategories/dinner.webp';
import disaster_relief from '../static/images/subcategories/disaster_relief.webp';
import diy from '../static/images/subcategories/diy.webp';
import djdance from '../static/images/subcategories/djdance.webp';
import drawingandpainting from '../static/images/subcategories/drawingandpainting.webp';
import easter from '../static/images/subcategories/easter.webp';
import eastern_religion from '../static/images/subcategories/eastern_religion.webp';
import edm from '../static/images/subcategories/edm.webp';
import edmelectronic from '../static/images/subcategories/edmelectronic.webp';
import education from '../static/images/subcategories/education.webp';
import educators from '../static/images/subcategories/educators.webp';
import electronic_music from '../static/images/subcategories/electronic_music.webp';
import environment from '../static/images/subcategories/environment.webp';
import environmentsustainability from '../static/images/subcategories/environmentsustainability.webp';
import exercise from '../static/images/subcategories/exercise.webp';
import experimental from '../static/images/subcategories/experimental.webp';
import fallevents from '../static/images/subcategories/fallevents.webp';
import fashion from '../static/images/subcategories/fashion.webp';
import federal_government from '../static/images/subcategories/federal_government.webp';
import fighting_martial_arts from '../static/images/subcategories/fighting_martial_arts.webp';
import film from '../static/images/subcategories/film.webp';
import finance from '../static/images/subcategories/finance.webp';
import fineart from '../static/images/subcategories/fineart.webp';
import first_aid from '../static/images/subcategories/first_aid.webp';
import folk from '../static/images/subcategories/folk.webp';
import food_sub from '../static/images/subcategories/food_sub.webp';
import football from '../static/images/subcategories/football.webp';
import fundraising from '../static/images/subcategories/fundraising.webp';
import gaming from '../static/images/subcategories/gaming.webp';
import golf from '../static/images/subcategories/golf.webp';
import halloween_new from '../static/images/subcategories/halloween_new.webp';
import hannukuh from '../static/images/subcategories/hannukuh.webp';
import healthcare from '../static/images/subcategories/healthcare.webp';
import heritage from '../static/images/subcategories/heritage.webp';
import hightech from '../static/images/subcategories/hightech.webp';
import hiking from '../static/images/subcategories/hiking.webp';
import hinduism from '../static/images/subcategories/hinduism.webp';
import hiphoprap from '../static/images/subcategories/hiphoprap.webp';
import historic from '../static/images/subcategories/historic.webp';
import hockey from '../static/images/subcategories/hockey.webp';
import home_and_garden from '../static/images/subcategories/home_and_garden.webp';
import human_rights from '../static/images/subcategories/human_rights.webp';
import independence_day from '../static/images/subcategories/independence_day.webp';
import indie from '../static/images/subcategories/indie.webp';
import international_affairs from '../static/images/subcategories/international_affairs.webp';
import investment from '../static/images/subcategories/investment.webp';
import islam from '../static/images/subcategories/islam.webp';
import jazz from '../static/images/subcategories/jazz.webp';
import jewelry from '../static/images/subcategories/jewelry.webp';
import judaism from '../static/images/subcategories/judaism.webp';
import kayaking from '../static/images/subcategories/kayaking.webp';
import knitting from '../static/images/subcategories/knitting.webp';
import lacross from '../static/images/subcategories/lacross.webp';
import language from '../static/images/subcategories/language.webp';
import latin_music from '../static/images/subcategories/latin_music.webp';
import literaryarts from '../static/images/subcategories/literaryarts.webp';
import media from '../static/images/subcategories/media.webp';
import medical from '../static/images/subcategories/medical.webp';
import medicine from '../static/images/subcategories/medicine.webp';
import medieval from '../static/images/subcategories/medieval.webp';
import mental_health from '../static/images/subcategories/mental_health.webp';
import metal_music from '../static/images/subcategories/metal_music.webp';
import military from '../static/images/subcategories/military.webp';
import mobile from '../static/images/subcategories/mobile.webp';
import mormonism from '../static/images/subcategories/mormonism.webp';
import motorcycle from '../static/images/subcategories/motorcycle.webp';
import motorsports from '../static/images/subcategories/motorsports.webp';
import mountain_biking from '../static/images/subcategories/mountain_biking.webp';
import musical from '../static/images/subcategories/musical.webp';
import mysticism from '../static/images/subcategories/mysticism.webp';
import nationality from '../static/images/subcategories/nationality.webp';
import national_security from '../static/images/subcategories/national_security.webp';
import newyearseve from '../static/images/subcategories/newyearseve.webp';
import new_age from '../static/images/subcategories/new_age.webp';
import nonpartisan from '../static/images/subcategories/nonpartisan.webp';
import nonprofitandngos from '../static/images/subcategories/nonprofitandngos.webp';
import obstacles from '../static/images/subcategories/obstacles.webp';
import opera from '../static/images/subcategories/opera.webp';
import orchestra from '../static/images/subcategories/orchestra.webp';
import other_party from '../static/images/subcategories/other_party.webp';
import painting from '../static/images/subcategories/painting.webp';
import parenting from '../static/images/subcategories/parenting.webp';
import parents_association from '../static/images/subcategories/parents_association.webp';
import parking from '../static/images/subcategories/parking.webp';
import personal_health from '../static/images/subcategories/personal_health.webp';
import petsandanimals from '../static/images/subcategories/petsandanimals.webp';
import photography from '../static/images/subcategories/photography.webp';
import pop_music from '../static/images/subcategories/pop_music.webp';
import poverty from '../static/images/subcategories/poverty.webp';
import pride from '../static/images/subcategories/pride.webp';
import psychedlic from '../static/images/subcategories/psychedlic.webp';
import public_speaker from '../static/images/subcategories/public_speaker.webp';
import punk_music from '../static/images/subcategories/punk_music.webp';
import raffle from '../static/images/subcategories/raffle.webp';
import rafting from '../static/images/subcategories/rafting.webp';
import randb from '../static/images/subcategories/randb.webp';
import realestate from '../static/images/subcategories/realestate.webp';
import reggae from '../static/images/subcategories/reggae.webp';
import religious_music from '../static/images/subcategories/religious_music.webp';
import renaissance from '../static/images/subcategories/renaissance.webp';
import republican_party from '../static/images/subcategories/republican_party.webp';
import reunion from '../static/images/subcategories/reunion.webp';
import robotics from '../static/images/subcategories/robotics.webp';
import rock from '../static/images/subcategories/rock.webp';
import rugby from '../static/images/subcategories/rugby.webp';
import running from '../static/images/subcategories/running.webp';
import sales_and_marketing from '../static/images/subcategories/sales_and_marketing.webp';
import science from '../static/images/subcategories/science.webp';
import senior_citizen from '../static/images/subcategories/senior_citizen.webp';
import shintoism from '../static/images/subcategories/shintoism.webp';
import sikhism from '../static/images/subcategories/sikhism.webp';
import singersongwriter from '../static/images/subcategories/singersongwriter.webp';
import snow_sports from '../static/images/subcategories/snow_sports.webp';
import soccer from '../static/images/subcategories/soccer.webp';
import social_media from '../static/images/subcategories/social_media.webp';
import softball from '../static/images/subcategories/softball.webp';
import spa from '../static/images/subcategories/spa.webp';
import spirits from '../static/images/subcategories/spirits.webp';
import startup_small_business from '../static/images/subcategories/startup_small_business.webp';
import state from '../static/images/subcategories/state.webp';
import state_government from '../static/images/subcategories/state_government.webp';
import st_patricks_day from '../static/images/subcategories/st_patricks_day.webp';
import accessories_small from '../static/images/subcategories/subcategory_small/accessories_small.webp';
import acoustic_small from '../static/images/subcategories/subcategory_small/acoustic_small.webp';
import adult_small from '../static/images/subcategories/subcategory_small/adult_small.webp';
import aetheism_small from '../static/images/subcategories/subcategory_small/aetheism_small.webp';
import after_school_care_small from '../static/images/subcategories/subcategory_small/after_school_care_small.webp';
import agnostic_small from '../static/images/subcategories/subcategory_small/agnostic_small.webp';
import airshow_small from '../static/images/subcategories/subcategory_small/airshow_small.webp';
import alternative_music_small from '../static/images/subcategories/subcategory_small/alternative_music_small.webp';
import alumni_small from '../static/images/subcategories/subcategory_small/alumni_small.webp';
import americana_music_small from '../static/images/subcategories/subcategory_small/americana_music_small.webp';
import animalwelfare_small from '../static/images/subcategories/subcategory_small/animalwelfare_small.webp';
import anime_small from '../static/images/subcategories/subcategory_small/anime_small.webp';
import auto_small from '../static/images/subcategories/subcategory_small/auto_small.webp';
import baby_small from '../static/images/subcategories/subcategory_small/baby_small.webp';
import ballet_small from '../static/images/subcategories/subcategory_small/ballet_small.webp';
import baseball_small from '../static/images/subcategories/subcategory_small/baseball_small.webp';
import basketball_small from '../static/images/subcategories/subcategory_small/basketball_small.webp';
import beauty_small from '../static/images/subcategories/subcategory_small/beauty_small.webp';
import beer_small from '../static/images/subcategories/subcategory_small/beer_small.webp';
import bluegrass_small from '../static/images/subcategories/subcategory_small/bluegrass_small.webp';
import blues_and_jazz_small from '../static/images/subcategories/subcategory_small/blues_and_jazz_small.webp';
import blues_small from '../static/images/subcategories/subcategory_small/blues_small.webp';
import boat_small from '../static/images/subcategories/subcategory_small/boat_small.webp';
import books_small from '../static/images/subcategories/subcategory_small/books_small.webp';
import bridal_small from '../static/images/subcategories/subcategory_small/bridal_small.webp';
import buddhism_small from '../static/images/subcategories/subcategory_small/buddhism_small.webp';
import camp_small from '../static/images/subcategories/subcategory_small/camp_small.webp';
import canoeing_small from '../static/images/subcategories/subcategory_small/canoeing_small.webp';
import career_small from '../static/images/subcategories/subcategory_small/career_small.webp';
import cheer_small from '../static/images/subcategories/subcategory_small/cheer_small.webp';
import childrenandyouth_small from '../static/images/subcategories/subcategory_small/childrenandyouth_small.webp';
import christianity_small from '../static/images/subcategories/subcategory_small/christianity_small.webp';
import christmas_small from '../static/images/subcategories/subcategory_small/christmas_small.webp';
import city_town_small from '../static/images/subcategories/subcategory_small/city_town_small.webp';
import classical_music_small from '../static/images/subcategories/subcategory_small/classical_music_small.webp';
import climbing_small from '../static/images/subcategories/subcategory_small/climbing_small.webp';
import comedy_small from '../static/images/subcategories/subcategory_small/comedy_small.webp';
import comics_small from '../static/images/subcategories/subcategory_small/comics_small.webp';
import country_music_small from '../static/images/subcategories/subcategory_small/country_music_small.webp';
import county_government_small from '../static/images/subcategories/subcategory_small/county_government_small.webp';
import county_small from '../static/images/subcategories/subcategory_small/county_small.webp';
import craft_small from '../static/images/subcategories/subcategory_small/craft_small.webp';
import cultural_music_small from '../static/images/subcategories/subcategory_small/cultural_music_small.webp';
import cycling_small from '../static/images/subcategories/subcategory_small/cycling_small.webp';
import dance_small from '../static/images/subcategories/subcategory_small/dance_small.webp';
import dating_small from '../static/images/subcategories/subcategory_small/dating_small.webp';
import democratic_party_small from '../static/images/subcategories/subcategory_small/democratic_party_small.webp';
import design_small from '../static/images/subcategories/subcategory_small/design_small.webp';
import dinner_small from '../static/images/subcategories/subcategory_small/dinner_small.webp';
import disaster_relief_small from '../static/images/subcategories/subcategory_small/disaster_relief_small.webp';
import diy_small from '../static/images/subcategories/subcategory_small/diy_small.webp';
import djdance_small from '../static/images/subcategories/subcategory_small/djdance_small.webp';
import drawingandpainting_small from '../static/images/subcategories/subcategory_small/drawingandpainting_small.webp';
import eastern_religion_small from '../static/images/subcategories/subcategory_small/eastern_religion_small.webp';
import easter_small from '../static/images/subcategories/subcategory_small/easter_small.webp';
import edmelectronic_small from '../static/images/subcategories/subcategory_small/edmelectronic_small.webp';
import edm_small from '../static/images/subcategories/subcategory_small/edm_small.webp';
import education_small from '../static/images/subcategories/subcategory_small/education_small.webp';
import educators_small from '../static/images/subcategories/subcategory_small/educators_small.webp';
import electronic_music_small from '../static/images/subcategories/subcategory_small/electronic_music_small.webp';
import environmentsustainability_small from '../static/images/subcategories/subcategory_small/environmentsustainability_small.webp';
import environment_small from '../static/images/subcategories/subcategory_small/environment_small.webp';
import exercise_small from '../static/images/subcategories/subcategory_small/exercise_small.webp';
import experimental_small from '../static/images/subcategories/subcategory_small/experimental_small.webp';
import fallevents_small from '../static/images/subcategories/subcategory_small/fallevents_small.webp';
import fashion_small from '../static/images/subcategories/subcategory_small/fashion_small.webp';
import federal_government_small from '../static/images/subcategories/subcategory_small/federal_government_small.webp';
import fighting_martial_arts_small from '../static/images/subcategories/subcategory_small/fighting_martial_arts_small.webp';
import film_small from '../static/images/subcategories/subcategory_small/film_small.webp';
import finance_small from '../static/images/subcategories/subcategory_small/finance_small.webp';
import fineart_small from '../static/images/subcategories/subcategory_small/fineart_small.webp';
import first_aid_small from '../static/images/subcategories/subcategory_small/first_aid_small.webp';
import folk_small from '../static/images/subcategories/subcategory_small/folk_small.webp';
import food_sub_small from '../static/images/subcategories/subcategory_small/food_sub_small.webp';
import football_small from '../static/images/subcategories/subcategory_small/football_small.webp';
import fundraising_small from '../static/images/subcategories/subcategory_small/fundraising_small.webp';
import gaming_small from '../static/images/subcategories/subcategory_small/gaming_small.webp';
import golf_small from '../static/images/subcategories/subcategory_small/golf_small.webp';
import halloweenhaunt_small from '../static/images/subcategories/subcategory_small/halloweenhaunt_small.webp';
import hannukuh_small from '../static/images/subcategories/subcategory_small/hannukuh_small.webp';
import healthcare_small from '../static/images/subcategories/subcategory_small/healthcare_small.webp';
import heritage_small from '../static/images/subcategories/subcategory_small/heritage_small.webp';
import hightech_small from '../static/images/subcategories/subcategory_small/hightech_small.webp';
import hiking_small from '../static/images/subcategories/subcategory_small/hiking_small.webp';
import hinduism_small from '../static/images/subcategories/subcategory_small/hinduism_small.webp';
import hiphoprap_small from '../static/images/subcategories/subcategory_small/hiphoprap_small.webp';
import historic_small from '../static/images/subcategories/subcategory_small/historic_small.webp';
import hockey_small from '../static/images/subcategories/subcategory_small/hockey_small.webp';
import home_and_garden_small from '../static/images/subcategories/subcategory_small/home_and_garden_small.webp';
import human_rights_small from '../static/images/subcategories/subcategory_small/human_rights_small.webp';
import independence_day_small from '../static/images/subcategories/subcategory_small/independence_day_small.webp';
import indie_small from '../static/images/subcategories/subcategory_small/indie_small.webp';
import international_affairs_small from '../static/images/subcategories/subcategory_small/international_affairs_small.webp';
import investment_small from '../static/images/subcategories/subcategory_small/investment_small.webp';
import islam_small from '../static/images/subcategories/subcategory_small/islam_small.webp';
import jazz_small from '../static/images/subcategories/subcategory_small/jazz_small.webp';
import jewelry_small from '../static/images/subcategories/subcategory_small/jewelry_small.webp';
import judaism_small from '../static/images/subcategories/subcategory_small/judaism_small.webp';
import kayaking_small from '../static/images/subcategories/subcategory_small/kayaking_small.webp';
import knitting_small from '../static/images/subcategories/subcategory_small/knitting_small.webp';
import lacross_small from '../static/images/subcategories/subcategory_small/lacross_small.webp';
import language_small from '../static/images/subcategories/subcategory_small/language_small.webp';
import latin_music_small from '../static/images/subcategories/subcategory_small/latin_music_small.webp';
import literaryarts_small from '../static/images/subcategories/subcategory_small/literaryarts_small.webp';
import media_small from '../static/images/subcategories/subcategory_small/media_small.webp';
import medical_small from '../static/images/subcategories/subcategory_small/medical_small.webp';
import medicine_small from '../static/images/subcategories/subcategory_small/medicine_small.webp';
import medieval_small from '../static/images/subcategories/subcategory_small/medieval_small.webp';
import mental_health_small from '../static/images/subcategories/subcategory_small/mental_health_small.webp';
import metal_music_small from '../static/images/subcategories/subcategory_small/metal_music_small.webp';
import military_small from '../static/images/subcategories/subcategory_small/military_small.webp';
import mobile_small from '../static/images/subcategories/subcategory_small/mobile_small.webp';
import mormonism_small from '../static/images/subcategories/subcategory_small/mormonism_small.webp';
import motorcycle_small from '../static/images/subcategories/subcategory_small/motorcycle_small.webp';
import motorsports_small from '../static/images/subcategories/subcategory_small/motorsports_small.webp';
import mountain_biking_small from '../static/images/subcategories/subcategory_small/mountain_biking_small.webp';
import musical_small from '../static/images/subcategories/subcategory_small/musical_small.webp';
import mysticism_small from '../static/images/subcategories/subcategory_small/mysticism_small.webp';
import nationality_small from '../static/images/subcategories/subcategory_small/nationality_small.webp';
import national_security_small from '../static/images/subcategories/subcategory_small/national_security_small.webp';
import newyearseve_small from '../static/images/subcategories/subcategory_small/newyearseve_small.webp';
import new_age_small from '../static/images/subcategories/subcategory_small/new_age_small.webp';
import nonpartisan_small from '../static/images/subcategories/subcategory_small/nonpartisan_small.webp';
import nonprofitandngos_small from '../static/images/subcategories/subcategory_small/nonprofitandngos_small.webp';
import obstacles_small from '../static/images/subcategories/subcategory_small/obstacles_small.webp';
import opera_small from '../static/images/subcategories/subcategory_small/opera_small.webp';
import orchestra_small from '../static/images/subcategories/subcategory_small/orchestra_small.webp';
import other_party_small from '../static/images/subcategories/subcategory_small/other_party_small.webp';
import painting_small from '../static/images/subcategories/subcategory_small/painting_small.webp';
import parenting_small from '../static/images/subcategories/subcategory_small/parenting_small.webp';
import parents_association_small from '../static/images/subcategories/subcategory_small/parents_association_small.webp';
import parking_small from '../static/images/subcategories/subcategory_small/parking_small.webp';
import personal_health_small from '../static/images/subcategories/subcategory_small/personal_health_small.webp';
import petsandanimals_small from '../static/images/subcategories/subcategory_small/petsandanimals_small.webp';
import photography_small from '../static/images/subcategories/subcategory_small/photography_small.webp';
import pop_music_small from '../static/images/subcategories/subcategory_small/pop_music_small.webp';
import poverty_small from '../static/images/subcategories/subcategory_small/poverty_small.webp';
import pride_small from '../static/images/subcategories/subcategory_small/pride_small.webp';
import psychedlic_small from '../static/images/subcategories/subcategory_small/psychedlic_small.webp';
import public_speaker_small from '../static/images/subcategories/subcategory_small/public_speaker_small.webp';
import punk_music_small from '../static/images/subcategories/subcategory_small/punk_music_small.webp';
import raffle_small from '../static/images/subcategories/subcategory_small/raffle_small.webp';
import rafting_small from '../static/images/subcategories/subcategory_small/rafting_small.webp';
import randb_small from '../static/images/subcategories/subcategory_small/randb_small.webp';
import realestate_small from '../static/images/subcategories/subcategory_small/realestate_small.webp';
import reggae_small from '../static/images/subcategories/subcategory_small/reggae_small.webp';
import religious_music_small from '../static/images/subcategories/subcategory_small/religious_music_small.webp';
import renaissance_small from '../static/images/subcategories/subcategory_small/renaissance_small.webp';
import republican_party_small from '../static/images/subcategories/subcategory_small/republican_party_small.webp';
import reunion_small from '../static/images/subcategories/subcategory_small/reunion_small.webp';
import robotics_small from '../static/images/subcategories/subcategory_small/robotics_small.webp';
import rock_small from '../static/images/subcategories/subcategory_small/rock_small.webp';
import rugby_small from '../static/images/subcategories/subcategory_small/rugby_small.webp';
import running_small from '../static/images/subcategories/subcategory_small/running_small.webp';
import sales_and_marketing_small from '../static/images/subcategories/subcategory_small/sales_and_marketing_small.webp';
import science_small from '../static/images/subcategories/subcategory_small/science_small.webp';
import senior_citizen_small from '../static/images/subcategories/subcategory_small/senior_citizen_small.webp';
import shintoism_small from '../static/images/subcategories/subcategory_small/shintoism_small.webp';
import sikhism_small from '../static/images/subcategories/subcategory_small/sikhism_small.webp';
import singersongwriter_small from '../static/images/subcategories/subcategory_small/singersongwriter_small.webp';
import snow_sports_small from '../static/images/subcategories/subcategory_small/snow_sports_small.webp';
import soccer_small from '../static/images/subcategories/subcategory_small/soccer_small.webp';
import social_media_small from '../static/images/subcategories/subcategory_small/social_media_small.webp';
import softball_small from '../static/images/subcategories/subcategory_small/softball_small.webp';
import spa_small from '../static/images/subcategories/subcategory_small/spa_small.webp';
import spirits_small from '../static/images/subcategories/subcategory_small/spirits_small.webp';
import startup_small_business_small from '../static/images/subcategories/subcategory_small/startup_small_business_small.webp';
import state_government_small from '../static/images/subcategories/subcategory_small/state_government_small.webp';
import state_small from '../static/images/subcategories/subcategory_small/state_small.webp';
import st_patricks_day_small from '../static/images/subcategories/subcategory_small/st_patricks_day_small.webp';
import swimmingandwatersports_small from '../static/images/subcategories/subcategory_small/swimmingandwatersports_small.webp';
import television_small from '../static/images/subcategories/subcategory_small/television_small.webp';
import tennis_small from '../static/images/subcategories/subcategory_small/tennis_small.webp';
import thanksgiving_small from '../static/images/subcategories/subcategory_small/thanksgiving_small.webp';
import theatre_small from '../static/images/subcategories/subcategory_small/theatre_small.webp';
import top40_music_small from '../static/images/subcategories/subcategory_small/top40_music_small.webp';
import track_and_field_small from '../static/images/subcategories/subcategory_small/track_and_field_small.webp';
import travel_small from '../static/images/subcategories/subcategory_small/travel_small.webp';
import unaffiliated_small from '../static/images/subcategories/subcategory_small/unaffiliated_small.webp';
import walking_small from '../static/images/subcategories/subcategory_small/walking_small.webp';
import weightlifting_small from '../static/images/subcategories/subcategory_small/weightlifting_small.webp';
import wine_small from '../static/images/subcategories/subcategory_small/wine_small.webp';
import world_music_small from '../static/images/subcategories/subcategory_small/world_music_small.webp';
import wrestling_small from '../static/images/subcategories/subcategory_small/wrestling_small.webp';
import yoga_small from '../static/images/subcategories/subcategory_small/yoga_small.webp';
import swimmingandwatersports from '../static/images/subcategories/swimmingandwatersports.webp';
import television from '../static/images/subcategories/television.webp';
import tennis from '../static/images/subcategories/tennis.webp';
import thanksgiving from '../static/images/subcategories/thanksgiving.webp';
import theatre from '../static/images/subcategories/theatre.webp';
import top40_music from '../static/images/subcategories/top40_music.webp';
import track_and_field from '../static/images/subcategories/track_and_field.webp';
import travel from '../static/images/subcategories/travel.webp';
import unaffiliated from '../static/images/subcategories/unaffiliated.webp';
import walking from '../static/images/subcategories/walking.webp';
import weightlifting from '../static/images/subcategories/weightlifting.webp';
import wine from '../static/images/subcategories/wine.webp';
import world_music from '../static/images/subcategories/world_music.webp';
import wrestling from '../static/images/subcategories/wrestling.webp';
import yoga from '../static/images/subcategories/yoga.webp';

import {
    AUTO_BOAT_AIR_EVENTBRITE_CATEGORY,
    BUSINESS_EVENTBRITE_CATEGORY,
    CHARITY_EVENTBRITE_CATEGORY,
    COMMUNITY_EVENTBRITE_CATEGORY,
    FAMILY_EDUCATION_EVENTBRITE_CATEGORY,
    FASHION_EVENTBRITE_CATEGORY,
    FILM_EVENTBRITE_CATEGORY,
    FOOD_AND_DRINK_EVENTBRITE_CATEGORY,
    HEALTH_EVENTBRITE_CATEGORY,
    HOBBIES_EVENTBRITE_CATEGORY,
    HOME_LIFESTYLE_EVENTBRITE_CATEGORY,
    MUSIC_EVENTBRITE_CATEGORY,
    OTHER_EVENTBRITE_CATEGORY,
    PERFORMING_EVENTBRITE_CATEGORY,
    POLITICS_EVENTBRITE_CATEGORY,
    RELIGION_EVENTBRITE_CATEGORY,
    SCHOOL_EVENTBRITE_CATEGORY,
    SCIENCE_EVENTBRITE_CATEGORY,
    SEASONAL_EVENTBRITE_CATEGORY,
    SPORTS_EVENTBRITE_CATEGORY,
    TRAVEL_EVENTBRITE_CATEGORY,
} from './categories';

export interface FormattedSubCategory {
    key: string;
    title: GenericLazyString;
    subtitle?: GenericLazyString;
    refinement: string;
    imageUrl: string;
    imageUrlSmall: string;
    theme?: string;
}

export const EVENTBRITE_SUB_CATEGORY = 'EventbriteSubCategory';

export const DATING = 17001;
export const INDEPENDENCE_DAY = 16003;
export const LGBT = 13004;
export const COMEDY = 5010;
export const HALLOWEEN = 16004;
export const FALL = 16008;
export const THANKSGIVING = 16005;
export const CHRISTMAS = 16006;
export const NYE = 16009;

export const DATING_EVENTBRITE_SUB_CATEGORY = `${EVENTBRITE_SUB_CATEGORY}/${DATING}`;
export const INDEPENDENCE_DAY_EVENTBRITE_SUB_CATEGORY = `${EVENTBRITE_SUB_CATEGORY}/${INDEPENDENCE_DAY}`;
export const LGBT_EVENTBRITE_SUB_CATEGORY = `${EVENTBRITE_SUB_CATEGORY}/${LGBT}`;
export const COMEDY_EVENTBRITE_SUB_CATEGORY = `${EVENTBRITE_SUB_CATEGORY}/${COMEDY}`;
export const HALLOWEEN_EVENTBRITE_SUB_CATEGORY = `${EVENTBRITE_SUB_CATEGORY}/${HALLOWEEN}`;
export const THANKSGIVING_EVENTBRITE_SUB_CATEGORY = `${EVENTBRITE_SUB_CATEGORY}/${THANKSGIVING}`;
export const CHRISTMAS_EVENTBRITE_SUB_CATEGORY = `${EVENTBRITE_SUB_CATEGORY}/${CHRISTMAS}`;
export const FALL_EVENTBRITE_SUB_CATEGORY = `${EVENTBRITE_SUB_CATEGORY}/${FALL}`;
export const NYE_EVENTBRITE_SUB_CATEGORY = `${EVENTBRITE_SUB_CATEGORY}/${NYE}`;

export const FORMATTED_SUBCATEGORY_MAP: Record<string, FormattedSubCategory> = {
    'EventbriteSubCategory/1001': {
        title: gettext('Startups & Small Business'),
        imageUrl: startup_small_business,
        imageUrlSmall: startup_small_business_small,
        key: 'EventbriteSubCategory/1001',
        refinement: 'startups-and-small-business',
    },
    'EventbriteSubCategory/1002': {
        title: gettext('Finance'),
        imageUrl: finance,
        imageUrlSmall: finance_small,
        key: 'EventbriteSubCategory/1002',
        refinement: 'finance',
    },
    'EventbriteSubCategory/1003': {
        title: gettext('Environment & Sustainability'),
        imageUrl: environmentsustainability,
        imageUrlSmall: environmentsustainability_small,
        key: 'EventbriteSubCategory/1003',
        refinement: 'environment-and-sustainability',
    },
    'EventbriteSubCategory/1004': {
        title: gettext('Educators'),
        imageUrl: educators,
        imageUrlSmall: educators_small,
        key: 'EventbriteSubCategory/1004',
        refinement: 'educators',
    },
    'EventbriteSubCategory/1005': {
        title: gettext('Real Estate'),
        imageUrl: realestate,
        imageUrlSmall: realestate_small,
        key: 'EventbriteSubCategory/1005',
        refinement: 'real-estate',
    },
    'EventbriteSubCategory/1006': {
        title: gettext('Non Profit & NGOs'),
        imageUrl: nonprofitandngos,
        imageUrlSmall: nonprofitandngos_small,
        key: 'EventbriteSubCategory/1006',
        refinement: 'non-profit-and-ngos',
    },
    'EventbriteSubCategory/1007': {
        title: gettext('Sales & Marketing'),
        imageUrl: sales_and_marketing,
        imageUrlSmall: sales_and_marketing_small,
        key: 'EventbriteSubCategory/1007',
        refinement: 'sales-and-marketing',
    },
    'EventbriteSubCategory/1008': {
        title: gettext('Media'),
        imageUrl: media,
        imageUrlSmall: media_small,
        key: 'EventbriteSubCategory/1008',
        refinement: 'media',
    },
    'EventbriteSubCategory/1009': {
        title: gettext('Design'),
        imageUrl: design,
        imageUrlSmall: design_small,
        key: 'EventbriteSubCategory/1009',
        refinement: 'design',
    },
    'EventbriteSubCategory/1010': {
        title: gettext('Career'),
        imageUrl: career,
        imageUrlSmall: career_small,
        key: 'EventbriteSubCategory/1010',
        refinement: 'career',
    },
    'EventbriteSubCategory/1011': {
        title: gettext('Investment'),
        imageUrl: investment,
        imageUrlSmall: investment_small,
        key: 'EventbriteSubCategory/1011',
        refinement: 'investment',
    },
    'EventbriteSubCategory/1999': {
        title: gettext('Other'),
        key: 'EventbriteSubCategory/1999',
        refinement: 'other',
        imageUrl: '',
        imageUrlSmall: '',
    },
    'EventbriteSubCategory/2001': {
        title: gettext('Medicine'),
        imageUrl: medicine,
        imageUrlSmall: medicine_small,
        key: 'EventbriteSubCategory/2001',
        refinement: 'medicine',
    },
    'EventbriteSubCategory/2002': {
        title: gettext('Science'),
        imageUrl: science,
        imageUrlSmall: science_small,
        key: 'EventbriteSubCategory/2002',
        refinement: 'science',
    },
    'EventbriteSubCategory/2003': {
        title: gettext('Biotech'),
        key: 'EventbriteSubCategory/2003',
        refinement: 'biotech',
        imageUrl: '',
        imageUrlSmall: '',
    },
    'EventbriteSubCategory/2004': {
        title: gettext('High Tech'),
        imageUrl: hightech,
        imageUrlSmall: hightech_small,
        key: 'EventbriteSubCategory/2004',
        refinement: 'high-tech',
    },
    'EventbriteSubCategory/2005': {
        title: gettext('Mobile'),
        imageUrl: mobile,
        imageUrlSmall: mobile_small,
        key: 'EventbriteSubCategory/2005',
        refinement: 'mobile',
    },
    'EventbriteSubCategory/2006': {
        title: gettext('Social Media'),
        imageUrl: social_media,
        imageUrlSmall: social_media_small,
        key: 'EventbriteSubCategory/2006',
        refinement: 'social-media',
    },
    'EventbriteSubCategory/2007': {
        title: gettext('Robotics'),
        imageUrl: robotics,
        imageUrlSmall: robotics_small,
        key: 'EventbriteSubCategory/2007',
        refinement: 'robotics',
    },
    'EventbriteSubCategory/2999': {
        title: gettext('Other'),
        key: 'EventbriteSubCategory/2999',
        refinement: 'other',
        imageUrl: '',
        imageUrlSmall: '',
    },
    'EventbriteSubCategory/3001': {
        title: gettext('Alternative'),
        imageUrl: alternative_music,
        imageUrlSmall: alternative_music_small,
        key: 'EventbriteSubCategory/3001',
        refinement: 'alternative',
    },
    'EventbriteSubCategory/3002': {
        title: gettext('Blues & Jazz'),
        imageUrl: blues_and_jazz,
        imageUrlSmall: blues_and_jazz_small,
        key: 'EventbriteSubCategory/3002',
        refinement: 'blues-and-jazz',
    },
    'EventbriteSubCategory/3003': {
        title: gettext('Classical'),
        imageUrl: classical_music,
        imageUrlSmall: classical_music_small,
        key: 'EventbriteSubCategory/3003',
        refinement: 'classical',
    },
    'EventbriteSubCategory/3004': {
        title: gettext('Country'),
        imageUrl: country_music,
        imageUrlSmall: country_music_small,
        key: 'EventbriteSubCategory/3004',
        refinement: 'country',
    },
    'EventbriteSubCategory/3005': {
        title: gettext('Cultural'),
        imageUrl: cultural_music,
        imageUrlSmall: cultural_music_small,
        key: 'EventbriteSubCategory/3005',
        refinement: 'cultural',
    },
    'EventbriteSubCategory/3006': {
        title: gettext('EDM / Electronic'),
        imageUrl: edmelectronic,
        imageUrlSmall: edmelectronic_small,
        key: 'EventbriteSubCategory/3006',
        refinement: 'edm--electronic',
    },
    'EventbriteSubCategory/3007': {
        title: gettext('Folk'),
        imageUrl: folk,
        imageUrlSmall: folk_small,
        key: 'EventbriteSubCategory/3007',
        refinement: 'folk',
    },
    'EventbriteSubCategory/3008': {
        title: gettext('Hip Hop / Rap'),
        imageUrl: hiphoprap,
        imageUrlSmall: hiphoprap_small,
        key: 'EventbriteSubCategory/3008',
        refinement: 'hip-hop--rap',
    },
    'EventbriteSubCategory/3009': {
        title: gettext('Indie'),
        imageUrl: indie,
        imageUrlSmall: indie_small,
        key: 'EventbriteSubCategory/3009',
        refinement: 'indie',
    },
    'EventbriteSubCategory/3010': {
        title: gettext('Latin'),
        imageUrl: latin_music,
        imageUrlSmall: latin_music_small,
        key: 'EventbriteSubCategory/3010',
        refinement: 'latin',
    },
    'EventbriteSubCategory/3011': {
        title: gettext('Metal'),
        imageUrl: metal_music,
        imageUrlSmall: metal_music_small,
        key: 'EventbriteSubCategory/3011',
        refinement: 'metal',
    },
    'EventbriteSubCategory/3012': {
        title: gettext('Opera'),
        imageUrl: opera,
        imageUrlSmall: opera_small,
        key: 'EventbriteSubCategory/3012',
        refinement: 'opera',
    },
    'EventbriteSubCategory/3013': {
        title: gettext('Pop'),
        imageUrl: pop_music,
        imageUrlSmall: pop_music_small,
        key: 'EventbriteSubCategory/3013',
        refinement: 'pop',
    },
    'EventbriteSubCategory/3014': {
        title: gettext('R&B'),
        imageUrl: randb,
        imageUrlSmall: randb_small,
        key: 'EventbriteSubCategory/3014',
        refinement: 'randb',
    },
    'EventbriteSubCategory/3015': {
        title: gettext('Reggae'),
        imageUrl: reggae,
        imageUrlSmall: reggae_small,
        key: 'EventbriteSubCategory/3015',
        refinement: 'reggae',
    },
    'EventbriteSubCategory/3016': {
        title: gettext('Religious/Spiritual'),
        imageUrl: religious_music,
        imageUrlSmall: religious_music_small,
        key: 'EventbriteSubCategory/3016',
        refinement: 'religiousspiritual',
    },
    'EventbriteSubCategory/3017': {
        title: gettext('Rock'),
        imageUrl: rock,
        imageUrlSmall: rock_small,
        key: 'EventbriteSubCategory/3017',
        refinement: 'rock',
    },
    'EventbriteSubCategory/3018': {
        title: gettext('Top 40'),
        imageUrl: top40_music,
        imageUrlSmall: top40_music_small,
        key: 'EventbriteSubCategory/3018',
        refinement: 'top-40',
    },
    'EventbriteSubCategory/3019': {
        title: gettext('Acoustic'),
        imageUrl: acoustic,
        imageUrlSmall: acoustic_small,
        key: 'EventbriteSubCategory/3019',
        refinement: 'acoustic',
    },
    'EventbriteSubCategory/3020': {
        title: gettext('Americana'),
        imageUrl: americana_music,
        imageUrlSmall: americana_music_small,
        key: 'EventbriteSubCategory/3020',
        refinement: 'americana',
    },
    'EventbriteSubCategory/3021': {
        title: gettext('Bluegrass'),
        imageUrl: bluegrass,
        imageUrlSmall: bluegrass_small,
        key: 'EventbriteSubCategory/3021',
        refinement: 'bluegrass',
    },
    'EventbriteSubCategory/3022': {
        title: gettext('Blues'),
        imageUrl: blues,
        imageUrlSmall: blues_small,
        key: 'EventbriteSubCategory/3022',
        refinement: 'blues',
    },
    'EventbriteSubCategory/3023': {
        title: gettext('DJ/Dance'),
        imageUrl: djdance,
        imageUrlSmall: djdance_small,
        key: 'EventbriteSubCategory/3023',
        refinement: 'djdance',
    },
    'EventbriteSubCategory/3024': {
        title: gettext('EDM'),
        imageUrl: edm,
        imageUrlSmall: edm_small,
        key: 'EventbriteSubCategory/3024',
        refinement: 'edm',
    },
    'EventbriteSubCategory/3025': {
        title: gettext('Electronic'),
        imageUrl: electronic_music,
        imageUrlSmall: electronic_music_small,
        key: 'EventbriteSubCategory/3025',
        refinement: 'electronic',
    },
    'EventbriteSubCategory/3026': {
        title: gettext('Experimental'),
        imageUrl: experimental,
        imageUrlSmall: experimental_small,
        key: 'EventbriteSubCategory/3026',
        refinement: 'experimental',
    },
    'EventbriteSubCategory/3027': {
        title: gettext('Jazz'),
        imageUrl: jazz,
        imageUrlSmall: jazz_small,
        key: 'EventbriteSubCategory/3027',
        refinement: 'jazz',
    },
    'EventbriteSubCategory/3028': {
        title: gettext('Psychedelic'),
        imageUrl: psychedlic,
        imageUrlSmall: psychedlic_small,
        key: 'EventbriteSubCategory/3028',
        refinement: 'psychedelic',
    },
    'EventbriteSubCategory/3029': {
        title: gettext('Punk/Hardcore'),
        imageUrl: punk_music,
        imageUrlSmall: punk_music_small,
        key: 'EventbriteSubCategory/3029',
        refinement: 'punkhardcore',
    },
    'EventbriteSubCategory/3030': {
        title: gettext('Singer/Songwriter'),
        imageUrl: singersongwriter,
        imageUrlSmall: singersongwriter_small,
        key: 'EventbriteSubCategory/3030',
        refinement: 'singersongwriter',
    },
    'EventbriteSubCategory/3031': {
        title: gettext('World'),
        imageUrl: world_music,
        imageUrlSmall: world_music_small,
        key: 'EventbriteSubCategory/3031',
        refinement: 'world',
    },
    'EventbriteSubCategory/3999': {
        title: gettext('Other'),
        key: 'EventbriteSubCategory/3999',
        refinement: 'other',
        imageUrl: '',
        imageUrlSmall: '',
    },
    'EventbriteSubCategory/4001': {
        title: gettext('TV'),
        imageUrl: television,
        imageUrlSmall: television_small,
        key: 'EventbriteSubCategory/4001',
        refinement: 'tv',
    },
    'EventbriteSubCategory/4002': {
        title: gettext('Film'),
        imageUrl: film,
        imageUrlSmall: film_small,
        key: 'EventbriteSubCategory/4002',
        refinement: 'film',
    },
    'EventbriteSubCategory/4003': {
        title: gettext('Anime'),
        imageUrl: anime,
        imageUrlSmall: anime_small,
        key: 'EventbriteSubCategory/4003',
        refinement: 'anime',
    },
    'EventbriteSubCategory/4004': {
        title: gettext('Gaming'),
        imageUrl: gaming,
        imageUrlSmall: gaming_small,
        key: 'EventbriteSubCategory/4004',
        refinement: 'gaming',
    },
    'EventbriteSubCategory/4005': {
        title: gettext('Comics'),
        imageUrl: comics,
        imageUrlSmall: comics_small,
        key: 'EventbriteSubCategory/4005',
        refinement: 'comics',
    },
    'EventbriteSubCategory/4006': {
        title: gettext('Adult'),
        imageUrl: adult,
        imageUrlSmall: adult_small,
        key: 'EventbriteSubCategory/4006',
        refinement: 'adult',
    },
    'EventbriteSubCategory/4007': {
        title: gettext('Comedy'),
        imageUrl: comedy,
        imageUrlSmall: comedy_small,
        key: 'EventbriteSubCategory/4007',
        refinement: 'comedy',
    },
    'EventbriteSubCategory/4999': {
        title: gettext('Other'),
        key: 'EventbriteSubCategory/4999',
        refinement: 'other',
        imageUrl: '',
        imageUrlSmall: '',
    },
    'EventbriteSubCategory/5001': {
        title: gettext('Theatre'),
        imageUrl: theatre,
        imageUrlSmall: theatre_small,
        key: 'EventbriteSubCategory/5001',
        refinement: 'theatre',
    },
    'EventbriteSubCategory/5002': {
        title: gettext('Musical'),
        imageUrl: musical,
        imageUrlSmall: musical_small,
        key: 'EventbriteSubCategory/5002',
        refinement: 'musical',
    },
    'EventbriteSubCategory/5003': {
        title: gettext('Ballet'),
        imageUrl: ballet,
        imageUrlSmall: ballet_small,
        key: 'EventbriteSubCategory/5003',
        refinement: 'ballet',
    },
    'EventbriteSubCategory/5004': {
        title: gettext('Dance'),
        imageUrl: dance,
        imageUrlSmall: dance_small,
        key: 'EventbriteSubCategory/5004',
        refinement: 'dance',
    },
    'EventbriteSubCategory/5005': {
        title: gettext('Opera'),
        imageUrl: opera,
        imageUrlSmall: opera_small,
        key: 'EventbriteSubCategory/5005',
        refinement: 'opera',
    },
    'EventbriteSubCategory/5006': {
        title: gettext('Orchestra'),
        imageUrl: orchestra,
        imageUrlSmall: orchestra_small,
        key: 'EventbriteSubCategory/5006',
        refinement: 'orchestra',
    },
    'EventbriteSubCategory/5007': {
        title: gettext('Craft'),
        imageUrl: craft,
        imageUrlSmall: craft_small,
        key: 'EventbriteSubCategory/5007',
        refinement: 'craft',
    },
    'EventbriteSubCategory/5008': {
        title: gettext('Fine Art'),
        imageUrl: fineart,
        imageUrlSmall: fineart_small,
        key: 'EventbriteSubCategory/5008',
        refinement: 'fine-art',
    },
    'EventbriteSubCategory/5009': {
        title: gettext('Literary Arts'),
        imageUrl: literaryarts,
        imageUrlSmall: literaryarts_small,
        key: 'EventbriteSubCategory/5009',
        refinement: 'literary-arts',
    },
    [COMEDY_EVENTBRITE_SUB_CATEGORY]: {
        title: gettext('Comedy'),
        imageUrl: comedy,
        imageUrlSmall: comedy_small,
        key: COMEDY_EVENTBRITE_SUB_CATEGORY,
        refinement: 'comedy',
    },
    'EventbriteSubCategory/5011': {
        title: gettext('Sculpture'),
        key: 'EventbriteSubCategory/5011',
        refinement: 'sculpture',
        imageUrl: '',
        imageUrlSmall: '',
    },
    'EventbriteSubCategory/5012': {
        title: gettext('Painting'),
        imageUrl: painting,
        imageUrlSmall: painting_small,
        key: 'EventbriteSubCategory/5012',
        refinement: 'painting',
    },
    'EventbriteSubCategory/5013': {
        title: gettext('Design'),
        imageUrl: design,
        imageUrlSmall: design_small,
        key: 'EventbriteSubCategory/5013',
        refinement: 'design',
    },
    'EventbriteSubCategory/5014': {
        title: gettext('Jewelry'),
        imageUrl: jewelry,
        imageUrlSmall: jewelry_small,
        key: 'EventbriteSubCategory/5014',
        refinement: 'jewelry',
    },
    'EventbriteSubCategory/5999': {
        title: gettext('Other'),
        key: 'EventbriteSubCategory/5999',
        refinement: 'other',
        imageUrl: '',
        imageUrlSmall: '',
    },
    'EventbriteSubCategory/6001': {
        title: gettext('Fashion'),
        imageUrl: fashion,
        imageUrlSmall: fashion_small,
        key: 'EventbriteSubCategory/6001',
        refinement: 'fashion',
    },
    'EventbriteSubCategory/6002': {
        title: gettext('Accessories'),
        imageUrl: accessories,
        imageUrlSmall: accessories_small,
        key: 'EventbriteSubCategory/6002',
        refinement: 'accessories',
    },
    'EventbriteSubCategory/6003': {
        title: gettext('Bridal'),
        imageUrl: bridal,
        imageUrlSmall: bridal_small,
        key: 'EventbriteSubCategory/6003',
        refinement: 'bridal',
    },
    'EventbriteSubCategory/6004': {
        title: gettext('Beauty'),
        imageUrl: beauty,
        imageUrlSmall: beauty_small,
        key: 'EventbriteSubCategory/6004',
        refinement: 'beauty',
    },
    'EventbriteSubCategory/6999': {
        title: gettext('Other'),
        key: 'EventbriteSubCategory/6999',
        refinement: 'other',
        imageUrl: '',
        imageUrlSmall: '',
    },
    'EventbriteSubCategory/7001': {
        title: gettext('Personal health'),
        imageUrl: personal_health,
        imageUrlSmall: personal_health_small,
        key: 'EventbriteSubCategory/7001',
        refinement: 'personal-health',
    },
    'EventbriteSubCategory/7002': {
        title: gettext('Mental health'),
        imageUrl: mental_health,
        imageUrlSmall: mental_health_small,
        key: 'EventbriteSubCategory/7002',
        refinement: 'mental-health',
    },
    'EventbriteSubCategory/7003': {
        title: gettext('Medical'),
        imageUrl: medical,
        imageUrlSmall: medical_small,
        key: 'EventbriteSubCategory/7003',
        refinement: 'medical',
    },
    'EventbriteSubCategory/7004': {
        title: gettext('Spa'),
        imageUrl: spa,
        imageUrlSmall: spa_small,
        key: 'EventbriteSubCategory/7004',
        refinement: 'spa',
    },
    'EventbriteSubCategory/7005': {
        title: gettext('Yoga'),
        imageUrl: yoga,
        imageUrlSmall: yoga_small,
        key: 'EventbriteSubCategory/7005',
        refinement: 'yoga',
    },
    'EventbriteSubCategory/7999': {
        title: gettext('Other'),
        key: 'EventbriteSubCategory/7999',
        refinement: 'other',
        imageUrl: '',
        imageUrlSmall: '',
    },
    'EventbriteSubCategory/8001': {
        title: gettext('Running'),
        imageUrl: running,
        imageUrlSmall: running_small,
        key: 'EventbriteSubCategory/8001',
        refinement: 'running',
    },
    'EventbriteSubCategory/8002': {
        title: gettext('Walking'),
        imageUrl: walking,
        imageUrlSmall: walking_small,
        key: 'EventbriteSubCategory/8002',
        refinement: 'walking',
    },
    'EventbriteSubCategory/8003': {
        title: gettext('Cycling'),
        imageUrl: cycling,
        imageUrlSmall: cycling_small,
        key: 'EventbriteSubCategory/8003',
        refinement: 'cycling',
    },
    'EventbriteSubCategory/8004': {
        title: gettext('Mountain Biking'),
        imageUrl: mountain_biking,
        imageUrlSmall: mountain_biking_small,
        key: 'EventbriteSubCategory/8004',
        refinement: 'mountain-biking',
    },
    'EventbriteSubCategory/8005': {
        title: gettext('Obstacles'),
        imageUrl: obstacles,
        imageUrlSmall: obstacles_small,
        key: 'EventbriteSubCategory/8005',
        refinement: 'obstacles',
    },
    'EventbriteSubCategory/8006': {
        title: gettext('Basketball'),
        imageUrl: basketball,
        imageUrlSmall: basketball_small,
        key: 'EventbriteSubCategory/8006',
        refinement: 'basketball',
    },
    'EventbriteSubCategory/8007': {
        title: gettext('Football'),
        imageUrl: football,
        imageUrlSmall: football_small,
        key: 'EventbriteSubCategory/8007',
        refinement: 'football',
    },
    'EventbriteSubCategory/8008': {
        title: gettext('Baseball'),
        imageUrl: baseball,
        imageUrlSmall: baseball_small,
        key: 'EventbriteSubCategory/8008',
        refinement: 'baseball',
    },
    'EventbriteSubCategory/8009': {
        title: gettext('Soccer'),
        imageUrl: soccer,
        imageUrlSmall: soccer_small,
        key: 'EventbriteSubCategory/8009',
        refinement: 'soccer',
    },
    'EventbriteSubCategory/8010': {
        title: gettext('Golf'),
        imageUrl: golf,
        imageUrlSmall: golf_small,
        key: 'EventbriteSubCategory/8010',
        refinement: 'golf',
    },
    'EventbriteSubCategory/8011': {
        title: gettext('Volleyball'),
        key: 'EventbriteSubCategory/8011',
        refinement: 'volleyball',
        imageUrl: '',
        imageUrlSmall: '',
    },
    'EventbriteSubCategory/8012': {
        title: gettext('Tennis'),
        imageUrl: tennis,
        imageUrlSmall: tennis_small,
        key: 'EventbriteSubCategory/8012',
        refinement: 'tennis',
    },
    'EventbriteSubCategory/8013': {
        title: gettext('Swimming & Water Sports'),
        imageUrl: swimmingandwatersports,
        imageUrlSmall: swimmingandwatersports_small,
        key: 'EventbriteSubCategory/8013',
        refinement: 'swimming-and-water-sports',
    },
    'EventbriteSubCategory/8014': {
        title: gettext('Hockey'),
        imageUrl: hockey,
        imageUrlSmall: hockey_small,
        key: 'EventbriteSubCategory/8014',
        refinement: 'hockey',
    },
    'EventbriteSubCategory/8015': {
        title: gettext('Motorsports'),
        imageUrl: motorsports,
        imageUrlSmall: motorsports_small,
        key: 'EventbriteSubCategory/8015',
        refinement: 'motorsports',
    },
    'EventbriteSubCategory/8016': {
        title: gettext('Fighting & Martial Arts'),
        imageUrl: fighting_martial_arts,
        imageUrlSmall: fighting_martial_arts_small,
        key: 'EventbriteSubCategory/8016',
        refinement: 'fighting-and-martial-arts',
    },
    'EventbriteSubCategory/8017': {
        title: gettext('Snow Sports'),
        imageUrl: snow_sports,
        imageUrlSmall: snow_sports_small,
        key: 'EventbriteSubCategory/8017',
        refinement: 'snow-sports',
    },
    'EventbriteSubCategory/8018': {
        title: gettext('Rugby'),
        imageUrl: rugby,
        imageUrlSmall: rugby_small,
        key: 'EventbriteSubCategory/8018',
        refinement: 'rugby',
    },
    'EventbriteSubCategory/8019': {
        title: gettext('Yoga'),
        imageUrl: yoga,
        imageUrlSmall: yoga_small,
        key: 'EventbriteSubCategory/8019',
        refinement: 'yoga',
    },
    'EventbriteSubCategory/8020': {
        title: gettext('Exercise'),
        imageUrl: exercise,
        imageUrlSmall: exercise_small,
        key: 'EventbriteSubCategory/8020',
        refinement: 'exercise',
    },
    'EventbriteSubCategory/8021': {
        title: gettext('Softball'),
        imageUrl: softball,
        imageUrlSmall: softball_small,
        key: 'EventbriteSubCategory/8021',
        refinement: 'softball',
    },
    'EventbriteSubCategory/8022': {
        title: gettext('Wrestling'),
        imageUrl: wrestling,
        imageUrlSmall: wrestling_small,
        key: 'EventbriteSubCategory/8022',
        refinement: 'wrestling',
    },
    'EventbriteSubCategory/8023': {
        title: gettext('Lacrosse'),
        imageUrl: lacross,
        imageUrlSmall: lacross_small,
        key: 'EventbriteSubCategory/8023',
        refinement: 'lacrosse',
    },
    'EventbriteSubCategory/8024': {
        title: gettext('Cheer'),
        imageUrl: cheer,
        imageUrlSmall: cheer_small,
        key: 'EventbriteSubCategory/8024',
        refinement: 'cheer',
    },
    'EventbriteSubCategory/8025': {
        title: gettext('Camps'),
        imageUrl: camp,
        imageUrlSmall: camp_small,
        key: 'EventbriteSubCategory/8025',
        refinement: 'camps',
    },
    'EventbriteSubCategory/8026': {
        title: gettext('Weightlifting'),
        imageUrl: weightlifting,
        imageUrlSmall: weightlifting_small,
        key: 'EventbriteSubCategory/8026',
        refinement: 'weightlifting',
    },
    'EventbriteSubCategory/8027': {
        title: gettext('Track & Field'),
        imageUrl: track_and_field,
        imageUrlSmall: track_and_field_small,
        key: 'EventbriteSubCategory/8027',
        refinement: 'track-and-field',
    },
    'EventbriteSubCategory/8999': {
        title: gettext('Other'),
        key: 'EventbriteSubCategory/8999',
        refinement: 'other',
        imageUrl: '',
        imageUrlSmall: '',
    },
    'EventbriteSubCategory/9001': {
        title: gettext('Hiking'),
        imageUrl: hiking,
        imageUrlSmall: hiking_small,
        key: 'EventbriteSubCategory/9001',
        refinement: 'hiking',
    },
    'EventbriteSubCategory/9002': {
        title: gettext('Rafting'),
        imageUrl: rafting,
        imageUrlSmall: rafting_small,
        key: 'EventbriteSubCategory/9002',
        refinement: 'rafting',
    },
    'EventbriteSubCategory/9003': {
        title: gettext('Kayaking'),
        imageUrl: kayaking,
        imageUrlSmall: kayaking_small,
        key: 'EventbriteSubCategory/9003',
        refinement: 'kayaking',
    },
    'EventbriteSubCategory/9004': {
        title: gettext('Canoeing'),
        imageUrl: canoeing,
        imageUrlSmall: canoeing_small,
        key: 'EventbriteSubCategory/9004',
        refinement: 'canoeing',
    },
    'EventbriteSubCategory/9005': {
        title: gettext('Climbing'),
        imageUrl: climbing,
        imageUrlSmall: climbing_small,
        key: 'EventbriteSubCategory/9005',
        refinement: 'climbing',
    },
    'EventbriteSubCategory/9006': {
        title: gettext('Travel'),
        imageUrl: travel,
        imageUrlSmall: travel_small,
        key: 'EventbriteSubCategory/9006',
        refinement: 'travel',
    },
    'EventbriteSubCategory/9999': {
        title: gettext('Other'),
        key: 'EventbriteSubCategory/9999',
        refinement: 'other',
        imageUrl: '',
        imageUrlSmall: '',
    },
    'EventbriteSubCategory/10001': {
        title: gettext('Beer'),
        imageUrl: beer,
        imageUrlSmall: beer_small,
        key: 'EventbriteSubCategory/10001',
        refinement: 'beer',
    },
    'EventbriteSubCategory/10002': {
        title: gettext('Wine'),
        imageUrl: wine,
        imageUrlSmall: wine_small,
        key: 'EventbriteSubCategory/10002',
        refinement: 'wine',
    },
    'EventbriteSubCategory/10003': {
        title: gettext('Food'),
        imageUrl: food_sub,
        imageUrlSmall: food_sub_small,
        key: 'EventbriteSubCategory/10003',
        refinement: 'food',
    },
    'EventbriteSubCategory/10004': {
        title: gettext('Spirits'),
        imageUrl: spirits,
        imageUrlSmall: spirits_small,
        key: 'EventbriteSubCategory/10004',
        refinement: 'spirits',
    },
    'EventbriteSubCategory/10999': {
        title: gettext('Other'),
        key: 'EventbriteSubCategory/10999',
        refinement: 'other',
        imageUrl: '',
        imageUrlSmall: '',
    },
    'EventbriteSubCategory/11001': {
        title: gettext('Animal Welfare'),
        imageUrl: animalwelfare,
        imageUrlSmall: animalwelfare_small,
        key: 'EventbriteSubCategory/11001',
        refinement: 'animal-welfare',
    },
    'EventbriteSubCategory/11002': {
        title: gettext('Environment'),
        imageUrl: environment,
        imageUrlSmall: environment_small,
        key: 'EventbriteSubCategory/11002',
        refinement: 'environment',
    },
    'EventbriteSubCategory/11003': {
        title: gettext('Healthcare'),
        imageUrl: healthcare,
        imageUrlSmall: healthcare_small,
        key: 'EventbriteSubCategory/11003',
        refinement: 'healthcare',
    },
    'EventbriteSubCategory/11004': {
        title: gettext('Human Rights'),
        imageUrl: human_rights,
        imageUrlSmall: human_rights_small,
        key: 'EventbriteSubCategory/11004',
        refinement: 'human-rights',
    },
    'EventbriteSubCategory/11005': {
        title: gettext('International Aid'),
        imageUrl: first_aid,
        imageUrlSmall: first_aid_small,
        key: 'EventbriteSubCategory/11005',
        refinement: 'international-aid',
    },
    'EventbriteSubCategory/11006': {
        title: gettext('Poverty'),
        imageUrl: poverty,
        imageUrlSmall: poverty_small,
        key: 'EventbriteSubCategory/11006',
        refinement: 'poverty',
    },
    'EventbriteSubCategory/11007': {
        title: gettext('Disaster Relief'),
        imageUrl: disaster_relief,
        imageUrlSmall: disaster_relief_small,
        key: 'EventbriteSubCategory/11007',
        refinement: 'disaster-relief',
    },
    'EventbriteSubCategory/11008': {
        title: gettext('Education'),
        imageUrl: education,
        imageUrlSmall: education_small,
        key: 'EventbriteSubCategory/11008',
        refinement: 'education',
    },
    'EventbriteSubCategory/11999': {
        title: gettext('Other'),
        key: 'EventbriteSubCategory/11999',
        refinement: 'other',
        imageUrl: '',
        imageUrlSmall: '',
    },
    'EventbriteSubCategory/12001': {
        title: gettext('Republican Party'),
        imageUrl: republican_party,
        imageUrlSmall: republican_party_small,
        key: 'EventbriteSubCategory/12001',
        refinement: 'republican-party',
    },
    'EventbriteSubCategory/12002': {
        title: gettext('Democratic Party'),
        imageUrl: democratic_party,
        imageUrlSmall: democratic_party_small,
        key: 'EventbriteSubCategory/12002',
        refinement: 'democratic-party',
    },
    'EventbriteSubCategory/12003': {
        title: gettext('Other Party'),
        imageUrl: other_party,
        imageUrlSmall: other_party_small,
        key: 'EventbriteSubCategory/12003',
        refinement: 'other-party',
    },
    'EventbriteSubCategory/12004': {
        title: gettext('Non-partisan'),
        imageUrl: nonpartisan,
        imageUrlSmall: nonpartisan_small,
        key: 'EventbriteSubCategory/12004',
        refinement: 'non-partisan',
    },
    'EventbriteSubCategory/12005': {
        title: gettext('Federal Government'),
        imageUrl: federal_government,
        imageUrlSmall: federal_government_small,
        key: 'EventbriteSubCategory/12005',
        refinement: 'federal-government',
    },
    'EventbriteSubCategory/12006': {
        title: gettext('State Government'),
        imageUrl: state_government,
        imageUrlSmall: state_government_small,
        key: 'EventbriteSubCategory/12006',
        refinement: 'state-government',
    },
    'EventbriteSubCategory/12007': {
        title: gettext('County/Municipal Government '),
        imageUrl: county_government,
        imageUrlSmall: county_government_small,
        key: 'EventbriteSubCategory/12007',
        refinement: 'countymunicipal-government-',
    },
    'EventbriteSubCategory/12008': {
        title: gettext('Military'),
        imageUrl: military,
        imageUrlSmall: military_small,
        key: 'EventbriteSubCategory/12008',
        refinement: 'military',
    },
    'EventbriteSubCategory/12009': {
        title: gettext('International Affairs'),
        imageUrl: international_affairs,
        imageUrlSmall: international_affairs_small,
        key: 'EventbriteSubCategory/12009',
        refinement: 'international-affairs',
    },
    'EventbriteSubCategory/12010': {
        title: gettext('National Security'),
        imageUrl: national_security,
        imageUrlSmall: national_security_small,
        key: 'EventbriteSubCategory/12010',
        refinement: 'national-security',
    },
    'EventbriteSubCategory/12999': {
        title: gettext('Other'),
        key: 'EventbriteSubCategory/12999',
        refinement: 'other',
        imageUrl: '',
        imageUrlSmall: '',
    },
    'EventbriteSubCategory/13001': {
        title: gettext('State'),
        imageUrl: state,
        imageUrlSmall: state_small,
        key: 'EventbriteSubCategory/13001',
        refinement: 'state',
    },
    'EventbriteSubCategory/13002': {
        title: gettext('County'),
        imageUrl: county,
        imageUrlSmall: county_small,
        key: 'EventbriteSubCategory/13002',
        refinement: 'county',
    },
    'EventbriteSubCategory/13003': {
        title: gettext('City/Town'),
        imageUrl: city_town,
        imageUrlSmall: city_town_small,
        key: 'EventbriteSubCategory/13003',
        refinement: 'citytown',
    },
    [LGBT_EVENTBRITE_SUB_CATEGORY]: {
        title: gettext('LGBTQIA+'),
        subtitle: gettext(
            'Show up, show off, and celebrate Pride with your people.',
        ),
        imageUrl: pride,
        imageUrlSmall: pride_small,
        key: LGBT_EVENTBRITE_SUB_CATEGORY,
        refinement: 'lgbt',
    },
    'EventbriteSubCategory/13005': {
        title: gettext('Medieval'),
        imageUrl: medieval,
        imageUrlSmall: medieval_small,
        key: 'EventbriteSubCategory/13005',
        refinement: 'medieval',
    },
    'EventbriteSubCategory/13006': {
        title: gettext('Renaissance'),
        imageUrl: renaissance,
        imageUrlSmall: renaissance_small,
        key: 'EventbriteSubCategory/13006',
        refinement: 'renaissance',
    },
    'EventbriteSubCategory/13007': {
        title: gettext('Heritage'),
        imageUrl: heritage,
        imageUrlSmall: heritage_small,
        key: 'EventbriteSubCategory/13007',
        refinement: 'heritage',
    },
    'EventbriteSubCategory/13008': {
        title: gettext('Nationality'),
        imageUrl: nationality,
        imageUrlSmall: nationality_small,
        key: 'EventbriteSubCategory/13008',
        refinement: 'nationality',
    },
    'EventbriteSubCategory/13009': {
        title: gettext('Language'),
        imageUrl: language,
        imageUrlSmall: language_small,
        key: 'EventbriteSubCategory/13009',
        refinement: 'language',
    },
    'EventbriteSubCategory/13010': {
        title: gettext('Historic'),
        imageUrl: historic,
        imageUrlSmall: historic_small,
        key: 'EventbriteSubCategory/13010',
        refinement: 'historic',
    },
    'EventbriteSubCategory/13999': {
        title: gettext('Other'),
        key: 'EventbriteSubCategory/13999',
        refinement: 'other',
        imageUrl: '',
        imageUrlSmall: '',
    },
    'EventbriteSubCategory/14001': {
        title: gettext('Christianity'),
        imageUrl: christianity,
        imageUrlSmall: christianity_small,
        key: 'EventbriteSubCategory/14001',
        refinement: 'christianity',
    },
    'EventbriteSubCategory/14002': {
        title: gettext('Judaism'),
        imageUrl: judaism,
        imageUrlSmall: judaism_small,
        key: 'EventbriteSubCategory/14002',
        refinement: 'judaism',
    },
    'EventbriteSubCategory/14003': {
        title: gettext('Islam'),
        imageUrl: islam,
        imageUrlSmall: islam_small,
        key: 'EventbriteSubCategory/14003',
        refinement: 'islam',
    },
    'EventbriteSubCategory/14004': {
        title: gettext('Mormonism'),
        imageUrl: mormonism,
        imageUrlSmall: mormonism_small,
        key: 'EventbriteSubCategory/14004',
        refinement: 'mormonism',
    },
    'EventbriteSubCategory/14005': {
        title: gettext('Buddhism'),
        imageUrl: buddhism,
        imageUrlSmall: buddhism_small,
        key: 'EventbriteSubCategory/14005',
        refinement: 'buddhism',
    },
    'EventbriteSubCategory/14006': {
        title: gettext('Sikhism'),
        imageUrl: sikhism,
        imageUrlSmall: sikhism_small,
        key: 'EventbriteSubCategory/14006',
        refinement: 'sikhism',
    },
    'EventbriteSubCategory/14007': {
        title: gettext('Eastern Religion'),
        imageUrl: eastern_religion,
        imageUrlSmall: eastern_religion_small,
        key: 'EventbriteSubCategory/14007',
        refinement: 'eastern-religion',
    },
    'EventbriteSubCategory/14008': {
        title: gettext('Mysticism and Occult'),
        imageUrl: mysticism,
        imageUrlSmall: mysticism_small,
        key: 'EventbriteSubCategory/14008',
        refinement: 'mysticism-and-occult',
    },
    'EventbriteSubCategory/14009': {
        title: gettext('New Age'),
        imageUrl: new_age,
        imageUrlSmall: new_age_small,
        key: 'EventbriteSubCategory/14009',
        refinement: 'new-age',
    },
    'EventbriteSubCategory/14010': {
        title: gettext('Atheism'),
        imageUrl: aetheism,
        imageUrlSmall: aetheism_small,
        key: 'EventbriteSubCategory/14010',
        refinement: 'atheism',
    },
    'EventbriteSubCategory/14011': {
        title: gettext('Agnosticism'),
        imageUrl: agnostic,
        imageUrlSmall: agnostic_small,
        key: 'EventbriteSubCategory/14011',
        refinement: 'agnosticism',
    },
    'EventbriteSubCategory/14012': {
        title: gettext('Unaffiliated'),
        imageUrl: unaffiliated,
        imageUrlSmall: unaffiliated_small,
        key: 'EventbriteSubCategory/14012',
        refinement: 'unaffiliated',
    },
    'EventbriteSubCategory/14013': {
        title: gettext('Hinduism'),
        imageUrl: hinduism,
        imageUrlSmall: hinduism_small,
        key: 'EventbriteSubCategory/14013',
        refinement: 'hinduism',
    },
    'EventbriteSubCategory/14014': {
        title: gettext('Folk Religions'),
        key: 'EventbriteSubCategory/14014',
        refinement: 'folk-religions',
        imageUrl: '',
        imageUrlSmall: '',
    },
    'EventbriteSubCategory/14015': {
        title: gettext('Shintoism'),
        imageUrl: shintoism,
        imageUrlSmall: shintoism_small,
        key: 'EventbriteSubCategory/14015',
        refinement: 'shintoism',
    },
    'EventbriteSubCategory/14099': {
        title: gettext('Other'),
        key: 'EventbriteSubCategory/14099',
        refinement: 'other',
        imageUrl: '',
        imageUrlSmall: '',
    },
    'EventbriteSubCategory/15001': {
        title: gettext('Education'),
        imageUrl: education,
        imageUrlSmall: education_small,
        key: 'EventbriteSubCategory/15001',
        refinement: 'education',
    },
    'EventbriteSubCategory/15002': {
        title: gettext('Alumni'),
        imageUrl: alumni,
        imageUrlSmall: alumni_small,
        key: 'EventbriteSubCategory/15002',
        refinement: 'alumni',
    },
    'EventbriteSubCategory/15003': {
        title: gettext('Parenting'),
        imageUrl: parenting,
        imageUrlSmall: parenting_small,
        key: 'EventbriteSubCategory/15003',
        refinement: 'parenting',
    },
    'EventbriteSubCategory/15004': {
        title: gettext('Baby'),
        imageUrl: baby,
        imageUrlSmall: baby_small,
        key: 'EventbriteSubCategory/15004',
        refinement: 'baby',
    },
    'EventbriteSubCategory/15005': {
        title: gettext('Children & Youth '),
        imageUrl: childrenandyouth,
        imageUrlSmall: childrenandyouth_small,
        key: 'EventbriteSubCategory/15005',
        refinement: 'children-and-youth-',
    },
    'EventbriteSubCategory/15006': {
        title: gettext('Parents Association'),
        imageUrl: parents_association,
        imageUrlSmall: parents_association_small,
        key: 'EventbriteSubCategory/15006',
        refinement: 'parents-association',
    },
    'EventbriteSubCategory/15007': {
        title: gettext('Reunion'),
        imageUrl: reunion,
        imageUrlSmall: reunion_small,
        key: 'EventbriteSubCategory/15007',
        refinement: 'reunion',
    },
    'EventbriteSubCategory/15008': {
        title: gettext('Senior Citizen'),
        imageUrl: senior_citizen,
        imageUrlSmall: senior_citizen_small,
        key: 'EventbriteSubCategory/15008',
        refinement: 'senior-citizen',
    },
    'EventbriteSubCategory/15999': {
        title: gettext('Other'),
        key: 'EventbriteSubCategory/15999',
        refinement: 'other',
        imageUrl: '',
        imageUrlSmall: '',
    },
    'EventbriteSubCategory/16001': {
        title: gettext('St Patricks Day'),
        imageUrl: st_patricks_day,
        imageUrlSmall: st_patricks_day_small,
        key: 'EventbriteSubCategory/16001',
        refinement: 'st-patricks-day',
    },
    'EventbriteSubCategory/16002': {
        title: gettext('Easter'),
        imageUrl: easter,
        imageUrlSmall: easter_small,
        key: 'EventbriteSubCategory/16002',
        refinement: 'easter',
    },
    [INDEPENDENCE_DAY_EVENTBRITE_SUB_CATEGORY]: {
        title: gettext('Independence Day'),
        imageUrl: independence_day,
        imageUrlSmall: independence_day_small,
        key: INDEPENDENCE_DAY_EVENTBRITE_SUB_CATEGORY,
        refinement: 'independence-day',
    },
    [HALLOWEEN_EVENTBRITE_SUB_CATEGORY]: {
        title: gettext('Halloween'),
        imageUrl: halloween_new,
        imageUrlSmall: halloweenhaunt_small,
        key: HALLOWEEN_EVENTBRITE_SUB_CATEGORY,
        refinement: 'halloweenhaunt',
    },
    [THANKSGIVING_EVENTBRITE_SUB_CATEGORY]: {
        title: gettext('Thanksgiving'),
        imageUrl: thanksgiving,
        imageUrlSmall: thanksgiving_small,
        key: THANKSGIVING_EVENTBRITE_SUB_CATEGORY,
        refinement: 'thanksgiving',
        theme: 'thanksgiving',
    },
    [CHRISTMAS_EVENTBRITE_SUB_CATEGORY]: {
        title: gettext('Christmas'),
        imageUrl: christmas,
        imageUrlSmall: christmas_small,
        key: CHRISTMAS_EVENTBRITE_SUB_CATEGORY,
        refinement: 'christmas',
        theme: 'christmas',
    },
    'EventbriteSubCategory/16007': {
        title: gettext('Hanukkah'),
        imageUrl: hannukuh,
        imageUrlSmall: hannukuh_small,
        key: 'EventbriteSubCategory/16007',
        refinement: 'channukah',
    },
    [FALL_EVENTBRITE_SUB_CATEGORY]: {
        title: gettext('Fall'),
        imageUrl: fallevents,
        imageUrlSmall: fallevents_small,
        key: FALL_EVENTBRITE_SUB_CATEGORY,
        refinement: 'fall-events',
        theme: 'fall',
    },
    [NYE_EVENTBRITE_SUB_CATEGORY]: {
        title: gettext("New Year's Eve"),
        imageUrl: newyearseve,
        imageUrlSmall: newyearseve_small,
        key: NYE_EVENTBRITE_SUB_CATEGORY,
        refinement: 'new-years-eve',
        theme: 'new-year',
    },
    'EventbriteSubCategory/16999': {
        title: gettext('Other'),
        key: 'EventbriteSubCategory/16999',
        refinement: 'other',
        imageUrl: '',
        imageUrlSmall: '',
    },
    [DATING_EVENTBRITE_SUB_CATEGORY]: {
        title: gettext('Dating'),
        subtitle: gettext(
            'Spark something new, no matter your relationship status.',
        ),
        imageUrl: dating,
        imageUrlSmall: dating_small,
        key: DATING_EVENTBRITE_SUB_CATEGORY,
        refinement: 'dating',
        theme: 'transparent',
    },
    'EventbriteSubCategory/17002': {
        title: gettext('Pets & Animals'),
        imageUrl: petsandanimals,
        imageUrlSmall: petsandanimals_small,
        key: 'EventbriteSubCategory/17002',
        refinement: 'pets-and-animals',
    },
    'EventbriteSubCategory/17003': {
        title: gettext('Home & Garden'),
        imageUrl: home_and_garden,
        imageUrlSmall: home_and_garden_small,
        key: 'EventbriteSubCategory/17003',
        refinement: 'home-and-garden',
    },
    'EventbriteSubCategory/17999': {
        title: gettext('Other'),
        key: 'EventbriteSubCategory/17999',
        refinement: 'other',
        imageUrl: '',
        imageUrlSmall: '',
    },
    'EventbriteSubCategory/18001': {
        title: gettext('Auto'),
        imageUrl: auto,
        imageUrlSmall: auto_small,
        key: 'EventbriteSubCategory/18001',
        refinement: 'auto',
    },
    'EventbriteSubCategory/18002': {
        title: gettext('Motorcycle/ATV'),
        imageUrl: motorcycle,
        imageUrlSmall: motorcycle_small,
        key: 'EventbriteSubCategory/18002',
        refinement: 'motorcycleatv',
    },
    'EventbriteSubCategory/18003': {
        title: gettext('Boat'),
        imageUrl: boat,
        imageUrlSmall: boat_small,
        key: 'EventbriteSubCategory/18003',
        refinement: 'boat',
    },
    'EventbriteSubCategory/18004': {
        title: gettext('Air'),
        imageUrl: airshow,
        imageUrlSmall: airshow_small,
        key: 'EventbriteSubCategory/18004',
        refinement: 'air',
    },
    'EventbriteSubCategory/18999': {
        title: gettext('Other'),
        key: 'EventbriteSubCategory/18999',
        refinement: 'other',
        imageUrl: '',
        imageUrlSmall: '',
    },
    'EventbriteSubCategory/19001': {
        title: gettext('Anime/Comics'),
        imageUrl: anime,
        imageUrlSmall: anime_small,
        key: 'EventbriteSubCategory/19001',
        refinement: 'animecomics',
    },
    'EventbriteSubCategory/19002': {
        title: gettext('Gaming'),
        imageUrl: gaming,
        imageUrlSmall: gaming_small,
        key: 'EventbriteSubCategory/19002',
        refinement: 'gaming',
    },
    'EventbriteSubCategory/19003': {
        title: gettext('DIY'),
        imageUrl: diy,
        imageUrlSmall: diy_small,
        key: 'EventbriteSubCategory/19003',
        refinement: 'diy',
    },
    'EventbriteSubCategory/19004': {
        title: gettext('Photography'),
        imageUrl: photography,
        imageUrlSmall: photography_small,
        key: 'EventbriteSubCategory/19004',
        refinement: 'photography',
    },
    'EventbriteSubCategory/19005': {
        title: gettext('Knitting'),
        imageUrl: knitting,
        imageUrlSmall: knitting_small,
        key: 'EventbriteSubCategory/19005',
        refinement: 'knitting',
    },
    'EventbriteSubCategory/19006': {
        title: gettext('Books'),
        imageUrl: books,
        imageUrlSmall: books_small,
        key: 'EventbriteSubCategory/19006',
        refinement: 'books',
    },
    'EventbriteSubCategory/19007': {
        title: gettext('Adult'),
        imageUrl: adult,
        imageUrlSmall: adult_small,
        key: 'EventbriteSubCategory/19007',
        refinement: 'adult',
    },
    'EventbriteSubCategory/19008': {
        title: gettext('Drawing & Painting'),
        imageUrl: drawingandpainting,
        imageUrlSmall: drawingandpainting_small,
        key: 'EventbriteSubCategory/19008',
        refinement: 'drawing-and-painting',
    },
    'EventbriteSubCategory/19999': {
        title: gettext('Other'),
        key: 'EventbriteSubCategory/19999',
        refinement: 'other',
        imageUrl: '',
        imageUrlSmall: '',
    },
    'EventbriteSubCategory/20001': {
        title: gettext('Dinner'),
        imageUrl: dinner,
        imageUrlSmall: dinner_small,
        key: 'EventbriteSubCategory/20001',
        refinement: 'dinner',
    },
    'EventbriteSubCategory/20002': {
        title: gettext('Fund Raiser'),
        imageUrl: fundraising,
        imageUrlSmall: fundraising_small,
        key: 'EventbriteSubCategory/20002',
        refinement: 'fund-raiser',
    },
    'EventbriteSubCategory/20003': {
        title: gettext('Raffle'),
        imageUrl: raffle,
        imageUrlSmall: raffle_small,
        key: 'EventbriteSubCategory/20003',
        refinement: 'raffle',
    },
    'EventbriteSubCategory/20004': {
        title: gettext('After School Care'),
        imageUrl: after_school_care,
        imageUrlSmall: after_school_care_small,
        key: 'EventbriteSubCategory/20004',
        refinement: 'after-school-care',
    },
    'EventbriteSubCategory/20005': {
        title: gettext('Parking'),
        imageUrl: parking,
        imageUrlSmall: parking_small,
        key: 'EventbriteSubCategory/20005',
        refinement: 'parking',
    },
    'EventbriteSubCategory/20006': {
        title: gettext('Public Speaker'),
        imageUrl: public_speaker,
        imageUrlSmall: public_speaker_small,
        key: 'EventbriteSubCategory/20006',
        refinement: 'public-speaker',
    },
};

/**
 * Generated with the following scripts:
 * const formatSubCat = (subcat: string) =>
    typeof subcat === 'string'
        ? subcat
              .replaceAll(' ', '-')
              .replaceAll('&', 'and')
              .replaceAll('/', '')
              .toLowerCase()
        : '';
 *
 * export const SUBCATEGORY_REFINEMENT_TO_KEY = Object.keys(
    ALL_SUBCATEGORY_MAP,
).reduce((memo, key) => {
    const formattedName = formatSubCat(
        ALL_SUBCATEGORY_MAP[key].title.toString(),
    );
    return { ...memo, [formattedName]: key };
}, {});
 */
// FIXME types [K in typeof EVENTBRITE_CATEGORY_ID_STRINGS[number]]: Record<
export const SUBCATEGORY_REFINEMENT_TO_KEY: Record<
    string,
    Record<string, string>
> = {
    [BUSINESS_EVENTBRITE_CATEGORY]: {
        'startups-and-small-business': 'EventbriteSubCategory/1001',
        finance: 'EventbriteSubCategory/1002',
        'environment-and-sustainability': 'EventbriteSubCategory/1003',
        educators: 'EventbriteSubCategory/1004',
        'real-estate': 'EventbriteSubCategory/1005',
        'non-profit-and-ngos': 'EventbriteSubCategory/1006',
        'sales-and-marketing': 'EventbriteSubCategory/1007',
        media: 'EventbriteSubCategory/1008',
        design: 'EventbriteSubCategory/1009',
        career: 'EventbriteSubCategory/1010',
        investment: 'EventbriteSubCategory/1011',
        other: 'EventbriteSubCategory/1999',
    },
    [SCIENCE_EVENTBRITE_CATEGORY]: {
        medicine: 'EventbriteSubCategory/2001',
        science: 'EventbriteSubCategory/2002',
        biotech: 'EventbriteSubCategory/2003',
        'high-tech': 'EventbriteSubCategory/2004',
        mobile: 'EventbriteSubCategory/2005',
        'social-media': 'EventbriteSubCategory/2006',
        robotics: 'EventbriteSubCategory/2007',
        other: 'EventbriteSubCategory/2999',
    },
    [MUSIC_EVENTBRITE_CATEGORY]: {
        alternative: 'EventbriteSubCategory/3001',
        'blues-and-jazz': 'EventbriteSubCategory/3002',
        classical: 'EventbriteSubCategory/3003',
        country: 'EventbriteSubCategory/3004',
        cultural: 'EventbriteSubCategory/3005',
        'edm--electronic': 'EventbriteSubCategory/3006',
        folk: 'EventbriteSubCategory/3007',
        'hip-hop--rap': 'EventbriteSubCategory/3008',
        indie: 'EventbriteSubCategory/3009',
        latin: 'EventbriteSubCategory/3010',
        metal: 'EventbriteSubCategory/3011',
        opera: 'EventbriteSubCategory/3012',
        pop: 'EventbriteSubCategory/3013',
        randb: 'EventbriteSubCategory/3014',
        reggae: 'EventbriteSubCategory/3015',
        religiousspiritual: 'EventbriteSubCategory/3016',
        rock: 'EventbriteSubCategory/3017',
        'top-40': 'EventbriteSubCategory/3018',
        acoustic: 'EventbriteSubCategory/3019',
        americana: 'EventbriteSubCategory/3020',
        bluegrass: 'EventbriteSubCategory/3021',
        blues: 'EventbriteSubCategory/3022',
        djdance: 'EventbriteSubCategory/3023',
        edm: 'EventbriteSubCategory/3024',
        electronic: 'EventbriteSubCategory/3025',
        experimental: 'EventbriteSubCategory/3026',
        jazz: 'EventbriteSubCategory/3027',
        psychedelic: 'EventbriteSubCategory/3028',
        punkhardcore: 'EventbriteSubCategory/3029',
        singersongwriter: 'EventbriteSubCategory/3030',
        world: 'EventbriteSubCategory/3031',
        other: 'EventbriteSubCategory/3999',
    },
    [FILM_EVENTBRITE_CATEGORY]: {
        tv: 'EventbriteSubCategory/4001',
        film: 'EventbriteSubCategory/4002',
        anime: 'EventbriteSubCategory/4003',
        gaming: 'EventbriteSubCategory/4004',
        comics: 'EventbriteSubCategory/4005',
        adult: 'EventbriteSubCategory/4006',
        comedy: 'EventbriteSubCategory/4007',
        other: 'EventbriteSubCategory/4999',
    },
    [PERFORMING_EVENTBRITE_CATEGORY]: {
        theatre: 'EventbriteSubCategory/5001',
        musical: 'EventbriteSubCategory/5002',
        ballet: 'EventbriteSubCategory/5003',
        dance: 'EventbriteSubCategory/5004',
        opera: 'EventbriteSubCategory/5005',
        orchestra: 'EventbriteSubCategory/5006',
        craft: 'EventbriteSubCategory/5007',
        'fine-art': 'EventbriteSubCategory/5008',
        'literary-arts': 'EventbriteSubCategory/5009',
        comedy: 'EventbriteSubCategory/5010',
        sculpture: 'EventbriteSubCategory/5011',
        painting: 'EventbriteSubCategory/5012',
        design: 'EventbriteSubCategory/5013',
        jewelry: 'EventbriteSubCategory/5014',
        other: 'EventbriteSubCategory/5999',
    },
    [FASHION_EVENTBRITE_CATEGORY]: {
        fashion: 'EventbriteSubCategory/6001',
        accessories: 'EventbriteSubCategory/6002',
        bridal: 'EventbriteSubCategory/6003',
        beauty: 'EventbriteSubCategory/6004',
        other: 'EventbriteSubCategory/6999',
    },
    [HEALTH_EVENTBRITE_CATEGORY]: {
        'personal-health': 'EventbriteSubCategory/7001',
        'mental-health': 'EventbriteSubCategory/7002',
        medical: 'EventbriteSubCategory/7003',
        spa: 'EventbriteSubCategory/7004',
        yoga: 'EventbriteSubCategory/7005',
        other: 'EventbriteSubCategory/7999',
    },
    [SPORTS_EVENTBRITE_CATEGORY]: {
        running: 'EventbriteSubCategory/8001',
        walking: 'EventbriteSubCategory/8002',
        cycling: 'EventbriteSubCategory/8003',
        'mountain-biking': 'EventbriteSubCategory/8004',
        obstacles: 'EventbriteSubCategory/8005',
        basketball: 'EventbriteSubCategory/8006',
        football: 'EventbriteSubCategory/8007',
        baseball: 'EventbriteSubCategory/8008',
        soccer: 'EventbriteSubCategory/8009',
        golf: 'EventbriteSubCategory/8010',
        volleyball: 'EventbriteSubCategory/8011',
        tennis: 'EventbriteSubCategory/8012',
        'swimming-and-water-sports': 'EventbriteSubCategory/8013',
        hockey: 'EventbriteSubCategory/8014',
        motorsports: 'EventbriteSubCategory/8015',
        'fighting-and-martial-arts': 'EventbriteSubCategory/8016',
        'snow-sports': 'EventbriteSubCategory/8017',
        rugby: 'EventbriteSubCategory/8018',
        yoga: 'EventbriteSubCategory/8019',
        exercise: 'EventbriteSubCategory/8020',
        softball: 'EventbriteSubCategory/8021',
        wrestling: 'EventbriteSubCategory/8022',
        lacrosse: 'EventbriteSubCategory/8023',
        cheer: 'EventbriteSubCategory/8024',
        camps: 'EventbriteSubCategory/8025',
        weightlifting: 'EventbriteSubCategory/8026',
        'track-and-field': 'EventbriteSubCategory/8027',
        other: 'EventbriteSubCategory/8999',
    },
    [TRAVEL_EVENTBRITE_CATEGORY]: {
        hiking: 'EventbriteSubCategory/9001',
        rafting: 'EventbriteSubCategory/9002',
        kayaking: 'EventbriteSubCategory/9003',
        canoeing: 'EventbriteSubCategory/9004',
        climbing: 'EventbriteSubCategory/9005',
        travel: 'EventbriteSubCategory/9006',
        other: 'EventbriteSubCategory/9999',
    },
    [FOOD_AND_DRINK_EVENTBRITE_CATEGORY]: {
        beer: 'EventbriteSubCategory/10001',
        wine: 'EventbriteSubCategory/10002',
        food: 'EventbriteSubCategory/10003',
        spirits: 'EventbriteSubCategory/10004',
        other: 'EventbriteSubCategory/10999',
    },
    [CHARITY_EVENTBRITE_CATEGORY]: {
        'animal-welfare': 'EventbriteSubCategory/11001',
        environment: 'EventbriteSubCategory/11002',
        healthcare: 'EventbriteSubCategory/11003',
        'human-rights': 'EventbriteSubCategory/11004',
        'international-aid': 'EventbriteSubCategory/11005',
        poverty: 'EventbriteSubCategory/11006',
        'disaster-relief': 'EventbriteSubCategory/11007',
        education: 'EventbriteSubCategory/11008',
        other: 'EventbriteSubCategory/11999',
    },
    [POLITICS_EVENTBRITE_CATEGORY]: {
        'republican-party': 'EventbriteSubCategory/12001',
        'democratic-party': 'EventbriteSubCategory/12002',
        'other-party': 'EventbriteSubCategory/12003',
        'non-partisan': 'EventbriteSubCategory/12004',
        'federal-government': 'EventbriteSubCategory/12005',
        'state-government': 'EventbriteSubCategory/12006',
        'countymunicipal-government-': 'EventbriteSubCategory/12007',
        military: 'EventbriteSubCategory/12008',
        'international-affairs': 'EventbriteSubCategory/12009',
        'national-security': 'EventbriteSubCategory/12010',
        other: 'EventbriteSubCategory/12999',
    },
    [COMMUNITY_EVENTBRITE_CATEGORY]: {
        state: 'EventbriteSubCategory/13001',
        county: 'EventbriteSubCategory/13002',
        citytown: 'EventbriteSubCategory/13003',
        lgbt: 'EventbriteSubCategory/13004',
        medieval: 'EventbriteSubCategory/13005',
        renaissance: 'EventbriteSubCategory/13006',
        heritage: 'EventbriteSubCategory/13007',
        nationality: 'EventbriteSubCategory/13008',
        language: 'EventbriteSubCategory/13009',
        historic: 'EventbriteSubCategory/13010',
        other: 'EventbriteSubCategory/13999',
    },
    [RELIGION_EVENTBRITE_CATEGORY]: {
        christianity: 'EventbriteSubCategory/14001',
        judaism: 'EventbriteSubCategory/14002',
        islam: 'EventbriteSubCategory/14003',
        mormonism: 'EventbriteSubCategory/14004',
        buddhism: 'EventbriteSubCategory/14005',
        sikhism: 'EventbriteSubCategory/14006',
        'eastern-religion': 'EventbriteSubCategory/14007',
        'mysticism-and-occult': 'EventbriteSubCategory/14008',
        'new-age': 'EventbriteSubCategory/14009',
        atheism: 'EventbriteSubCategory/14010',
        agnosticism: 'EventbriteSubCategory/14011',
        unaffiliated: 'EventbriteSubCategory/14012',
        hinduism: 'EventbriteSubCategory/14013',
        'folk-religions': 'EventbriteSubCategory/14014',
        shintoism: 'EventbriteSubCategory/14015',
        other: 'EventbriteSubCategory/14099',
    },
    [FAMILY_EDUCATION_EVENTBRITE_CATEGORY]: {
        education: 'EventbriteSubCategory/15001',
        alumni: 'EventbriteSubCategory/15002',
        parenting: 'EventbriteSubCategory/15003',
        baby: 'EventbriteSubCategory/15004',
        'children-and-youth-': 'EventbriteSubCategory/15005',
        'parents-association': 'EventbriteSubCategory/15006',
        reunion: 'EventbriteSubCategory/15007',
        'senior-citizen': 'EventbriteSubCategory/15008',
        other: 'EventbriteSubCategory/15999',
    },
    [SEASONAL_EVENTBRITE_CATEGORY]: {
        'st-patricks-day': 'EventbriteSubCategory/16001',
        easter: 'EventbriteSubCategory/16002',
        'independence-day': 'EventbriteSubCategory/16003',
        halloweenhaunt: 'EventbriteSubCategory/16004',
        thanksgiving: 'EventbriteSubCategory/16005',
        christmas: 'EventbriteSubCategory/16006',
        channukah: 'EventbriteSubCategory/16007',
        'fall-events': 'EventbriteSubCategory/16008',
        'new-years-eve': 'EventbriteSubCategory/16009',
        other: 'EventbriteSubCategory/16999',
    },
    [HOME_LIFESTYLE_EVENTBRITE_CATEGORY]: {
        dating: 'EventbriteSubCategory/17001',
        'pets-and-animals': 'EventbriteSubCategory/17002',
        'home-and-garden': 'EventbriteSubCategory/17003',
        other: 'EventbriteSubCategory/17999',
    },
    [AUTO_BOAT_AIR_EVENTBRITE_CATEGORY]: {
        auto: 'EventbriteSubCategory/18001',
        motorcycleatv: 'EventbriteSubCategory/18002',
        boat: 'EventbriteSubCategory/18003',
        air: 'EventbriteSubCategory/18004',
        other: 'EventbriteSubCategory/18999',
    },
    [HOBBIES_EVENTBRITE_CATEGORY]: {
        animecomics: 'EventbriteSubCategory/19001',
        gaming: 'EventbriteSubCategory/19002',
        diy: 'EventbriteSubCategory/19003',
        photography: 'EventbriteSubCategory/19004',
        knitting: 'EventbriteSubCategory/19005',
        books: 'EventbriteSubCategory/19006',
        adult: 'EventbriteSubCategory/19007',
        'drawing-and-painting': 'EventbriteSubCategory/19008',
        other: 'EventbriteSubCategory/19999',
    },
    [SCHOOL_EVENTBRITE_CATEGORY]: {
        dinner: 'EventbriteSubCategory/20001',
        'fund-raiser': 'EventbriteSubCategory/20002',
        raffle: 'EventbriteSubCategory/20003',
        'after-school-care': 'EventbriteSubCategory/20004',
        parking: 'EventbriteSubCategory/20005',
        'public-speaker': 'EventbriteSubCategory/20006',
    },
    [OTHER_EVENTBRITE_CATEGORY]: {},
};
