import { datadogRum } from '@datadog/browser-rum-slim';
import { EvaluationCallback } from 'statsig-js/dist/StatsigSDKOptions';
import { StatsigType } from './types';

export const evaluationCallback: EvaluationCallback = (args) => {
    if (args.type === StatsigType.EXPERIMENT) {
        const key = args.config.getName();
        const value = args.config.getGroupName();

        if (key && value) {
            datadogRum.addFeatureFlagEvaluation(
                `statsig_experiment_${key}`,
                value,
            );
        }
    }
};
