import type {
    CoreApplicationContext,
    CoreRequest,
    CoreUser,
    GASettingKeys,
} from '@eventbrite/context-gen';
import type { GenericOverlayOptions } from '@eventbrite/eds-structure';
import { DiscoveryEvent } from '@eventbrite/event-renderer';
import { GenericLazyString } from '@eventbrite/i18n';
import type { UrgencySignalsProps } from '@eventbrite/urgency-signals';
import type { EventDescriptionProps } from '../components/EventDetails/EventDescription';
import type { CarouselItems } from '../components/EventDetails/EventHero/HeroCarousel';
import type { PerformersProps } from '../components/EventDetails/Performers/Performers';
import type { RefundPolicyProps } from '../components/EventDetails/RefundPolicy/RefundPolicy';
import type { LocationProps } from '../components/EventDetails/WhenAndWhere/Location/Location';
import type { FooterLinks } from '../components/Footer/Footer';
import { HighlightsProps } from '../components/Highlights';
import type { NotificationBarSsrProps } from '../components/NotificationBar/NotificationBarSsr';
import type { OrganizerPanelLayoutProps } from '../components/OrganizerPanel/OrganizerPanelLayout';
import { ShareProps } from '../components/ShareEvent/ShareEventRoot';
import type { Tag } from '../components/Tags/Tags';
import type { TicketClass } from '../components/Tickets';

export interface Collection {
    id: string;
    name: string;
    url: string;
    summary: string;
    imageUrl?: string;
    upcomingEventsCount: number;
}

export interface CollectionResponse {
    image: {
        url: string;
    };
    event_count: {
        current_future_published: number;
    };
    absolute_url: string;
    id: string;
    image_id: string;
    name: string;
    organization_id: string;
    organizer_id: string;
    relative_url: string;
    slug: string;
    status: string;
    summary: string;
}

export interface EventCollectionsResponse {
    collections: CollectionResponse[];
    pagination: {
        object_count: number;
        has_more_items: boolean;
    };
}

export type DateAndTimeScopedProps = {
    displayDate: string;
    displayEndDateWithTz: string;
    displayStartDateWithTz: string;
    doorTime: string | null;
    doorTimeLineOne?: string;
    doorTimeLineTwo?: string;
    doorTimeLineThree?: string;
    dateLineTwo: string;
    dateLineOne: string;
    isSameStartEndDate: boolean;
    shouldDisplayCrawlableSeriesChildrenLinks: boolean;
    shouldShowDoorTime: boolean;
};

export interface LocalizedDateTime {
    utc: string;
    local: string;
    timezone: string;
}

export type ChildEventProps = {
    end: LocalizedDateTime;
    formatted_start_date: string;
    id: string;
    locale: string;
    start: LocalizedDateTime;
    url: string;
    short_start_date?: string;
    status?: string;
    sales_status?: string;
    is_sold_out?: boolean;
    is_sales_ended?: boolean;
    salesStatus?: string;
    availability?: string;
};

export type MusicEventProps = {
    presenter: string | undefined;
    ageRestriction: string | undefined;
    hasPresenterOrAgeRestriction: boolean;
};

export enum DiscountType {
    access = 'access',
    coded = 'coded',
    hold = 'hold',
    open = 'open',
    public = 'public',
}

export type Discount = {
    amountOff?: string;
    code?: string;
    discountType: keyof typeof DiscountType;
    percentOff?: string;
};

export type EBEvent = Event;

export type Event = ServerEvent & {
    isRepeating: boolean;
};

export type ServerEvent = {
    childEvents: Array<ChildEventProps> | null;
    compactCheckoutDisqualifications: CheckoutDisqualifications;
    category?: string;
    discount?: Discount;
    ticketsInfo: {
        hasBogoTickets: boolean;
        totalTicketsCount: number;
        hasEarlyBirdTickets?: boolean;
    };
    venue?: {
        country: string;
        region: string;
    };
    end: LocalizedDateTime;
    format?: string;
    hideEndDate: boolean;
    hideStartDate: boolean;
    id: string;
    isExpiredEvent: boolean;
    isOnlineEvent: boolean;
    isPreview: boolean;
    isSeriesParent: boolean;
    name: string;
    ownerId?: string;
    source: string;
    start: LocalizedDateTime;
    url: string;
    hasCroppedLogo: boolean;
    isEnded: boolean;
    isListed: boolean;
    isLive: boolean;
    isProtected: boolean;
    startDateTime: string;
    startMonthAbbr: string;
    startDay: string;
    hasDonationTicketsAvailable?: boolean;
    shouldDisplayRsvp: boolean;
    shouldShowSalesEndedMessage: boolean;
    shouldShowCompactCheckout: boolean;
    organizerSalesEndedMessage: string | null;
    salesEndedMessage: string | null;
    salesStatus: {
        sales_status: string;
        message: string;
    };
    subcategory?: string;
} & MusicEventProps;

export type CheckoutDisqualifications = {
    discount_code: boolean;
    has_addons: boolean;
    has_discounts: boolean;
    has_donations: boolean;
    has_not_waiting_room?: boolean;
    has_waiting_room: boolean;
    has_waitlist: boolean;
    is_canceled: boolean;
    is_postponed: boolean;
    is_rsvp: boolean;
    is_sales_ended: boolean;
    more_than_one_ticket: boolean;
    repeating_event?: boolean;
    reserved_seating: boolean;
    sold_out: boolean;
    has_inventory_tiers: boolean;
};

export interface Organizer {
    id: string;
    name: string;
    nameTruncated: string;
    url: string;
    profilePicture: string;
    displayOrganizationName: string;
    description?: string;
    eventTitle?: string;
    orgWebsite?: string;
    orgFacebook?: string;
    orgTwitter?: string;
    numberEventsHosted?: number;
}

export interface Location {
    latitude: number;
    longitude: number;
}

export interface User extends CoreUser {
    id?: number;
    canManageEvent?: boolean;
    activeOrganization?: ActiveOrganization;
    guestId?: string;
    isAuthenticated: boolean;
}

export interface ActiveOrganization {
    id?: string;
    guestId?: string;
}

export interface Request extends CoreRequest {
    force_cacheable: boolean;
}

export interface FeatureFlags {
    launchLinkToCreatorCollectionsOnListing?: boolean;
    launchMobilePreviewInEventCreationFlow?: boolean;
    enableIaGlobalNav?: boolean;
    enableSSRExperimentationOnListings?: boolean;
    enableReldExpressCheckoutChanges?: boolean;
    enableCreatorDefaultAvatar?: boolean;
    enableCreatorBadge?: boolean;
    enableDatadogRumLoadingFromListingsApp?: boolean;
    listingsRumSampleValue?: number;
    enableSeasonalModule?: boolean;
}

export interface Settings {
    baseUrl: string;
    isMobile: boolean;
    featureFlags?: FeatureFlags;
    sentryDNS: string;
}

export interface GASettings extends GASettingKeys {
    category: string;
}

export interface ShareEventScopedProps {
    facebookOptions: {
        locale: string;
        version: string;
        page: string;
        xfbml: boolean;
        appId: string;
    };
    twitterHandle: string;
}

export interface FollowOrganizerScopedProps {
    isGDPRCountry: boolean;
}

export interface ContactOrganizerScopedProps {
    captchaKey: string;
}

export interface CheckoutWidgetProps {
    affiliateCode: string;
    campaign_token?: string;
    promoCode?: string;
    rsvpToken?: string;
    waitlistToken?: string;
}

export interface EventMapScopedProps {
    venueName: string;
    venueAddress: string;
    location: Location;
    gMapsApiKey: string;
    shouldDisplayMap: boolean;
}

export interface MapScopedProps {
    location: Location;
    gMapsApiKey: string;
    shouldDisplayMap: boolean;
}

export interface RemindMeScopedProps {
    isRemindedEvent: boolean;
}

export interface BookmarkScopedProps {
    isBookmarkedEvent: boolean;
}

export type SeriesDatesScopedProps = {
    childEvents: Array<ChildEventProps>;
    eventId: string;
    isOnlineEvent?: boolean;
    isSoldOut?: boolean;
    isTimeEntryEnable?: boolean;
};

export type EventDateTimeProps = {
    end: LocalizedDateTime;
    hideEndDate: boolean;
    hideStartDate: boolean;
    isOnlineEvent: boolean;
    isSeriesParent: boolean;
    locale: string;
    start: LocalizedDateTime;
};

export interface OverlayOptions extends GenericOverlayOptions {
    kind: 'modal' | 'dialog';
}

export interface ModalOptions extends OverlayOptions {
    children: React.ReactNode;
    type?: string;
}

export interface DialogOptions extends OverlayOptions {
    message: string;
}

export interface OverlayProps {
    options: ModalOptions | DialogOptions | undefined;
}

export interface NotificationOptions {
    children: React.ReactNode;
    hasCloseButton?: boolean;
    isClosing?: boolean;
    isNotificationSticky?: boolean;
    onClose?: () => void;
    position?: string;
    shouldPersist?: boolean;
    shouldStick?: boolean;
    stickyTopHeight?: number;
    type?: string;
    autoHide?: boolean;
}

export interface NotificationProps {
    fullWidth?: boolean;
    options: NotificationOptions | undefined;
}

export type MainBodyBackgroundScopedProps = {
    croppedLogoUrl50?: string;
};

export type FeaturedVideoProps = {
    url: string;
    embedUrl: string;
    thumbnail?: {
        large?: {
            url: string;
        };
        medium?: {
            url: string;
        };
        small?: {
            url: string;
        };
    };
    provider?: string;
};

export enum Environment {
    DEV = 'dev',
    QA = 'qa',
    PROD = 'prod',
}

export type TimedEntryFeatureFlag = {
    enableTimedEntry: boolean;
};

export type EventDetailsProps = {
    performers: PerformersProps;
    location: LocationProps;
    onlineEvent: {
        url?: string;
    };
    refund: RefundPolicyProps;
    dates: DateAndTimeScopedProps;
};

export type EventHeroScopedProps = {
    items?: Array<CarouselItems>;
    featuredVideo?: FeaturedVideoProps;
    nativeVideo?: NativeVideoProps;
    mainBodyBackground: MainBodyBackgroundScopedProps;
};

export type LegacyConversionBarCommonProps = {
    isShareable: boolean;
    isEnded: boolean;
    statusIsUnavailable: boolean;
    shouldDisplayRsvp: boolean;
    shouldShowStatus: boolean;
    panelDisplayPrice: string;
    statusToDisplay: string;
    shouldShowCheckoutButton: boolean;
    getSeriesDefaultOptionTerminology: string;
    ticketModalButton: any;
    shouldShowRemindMeButton: boolean;
    shouldShowTurnOffReminderButton: boolean;
};

export type LegacyConversionBarProps = LegacyConversionBarCommonProps & {
    externalTicketsUrl: string;
    externalTicketsButtonText: string;
    externalTicketsAreAvailable: boolean;
    shouldShowExternalTicketingProvider: boolean;
    externalTicketingProviderLabelText: string | '';
};

export enum SALES_STATUS {
    SALES_ENDED = 'sales_ended',
    SOLD_OUT = 'sold_out',
    ON_SALE = 'on_sale',
    NOT_YET_ON_SALE = 'not_yet_on_sale',
    UNAVAILABLE = 'unavailable',
    ON_SALE_SOON = 'on_sale_soon',
    NOT_YET_ON_SALE_ACCESS_CODED = 'not_yet_on_sale_access_coded',
    // BFF may return unknown sales status if it is not able to retrieve the sales status from downstream services
    UNKNOWN = 'unknown',
    //Core may return an empty string if it's not able to retrieve the sales status for the current event
    DEFAULT = '',
}

export const UNDETERMINED_SALES_STATUSES = [
    SALES_STATUS.UNKNOWN,
    SALES_STATUS.DEFAULT,
];

export type SeasonalBannerProps = {
    title: GenericLazyString;
    subtitle: GenericLazyString;
    ctaLink: string;
    ctaText: GenericLazyString;
};

type StatisigInitilizeValues = any;

export type StatsigProps = {
    initializeValues: StatisigInitilizeValues;
    customParams?: Record<string, boolean | string>;
    stableId?: string;
};

export interface AppProps extends CoreApplicationContext {
    components: {
        conversionBar: LegacyConversionBarProps;
        shareEvent: ShareEventScopedProps;
        followOrganizer: FollowOrganizerScopedProps;
        checkoutWidget: CheckoutWidgetProps;
        contactOrganizer: ContactOrganizerScopedProps;
        eventMap: EventMapScopedProps;
        remindMe: RemindMeScopedProps;
        bookmark: BookmarkScopedProps;
        eventDescription: EventDescriptionProps;
        eventDetails: EventDetailsProps;
        tags: Array<Tag>;
        organizerPanel: OrganizerPanelLayoutProps;
        eventHero: EventHeroScopedProps;
        mainBodyBackground: MainBodyBackgroundScopedProps;
        notificationBar: NotificationBarSsrProps;
        relatedEventsExpiredPage: RelatedEventsExpiredPage;
        shareInline: any; // TODO add type
    };
    event: ServerEvent;
    gaSettings: GASettings;
    tracking: TrackingProps;
    settings: Settings;
    app_name: string;
    app_version: string;
    organizer: Organizer;
    user: User;
    footerLinks: FooterLinks;
    urgencySignals: UrgencySignalsProps;
    highlights: HighlightsProps;
    request: Request;
    salesStatus: string;
    statsig: StatsigProps;
    stableId?: string;
    event_listing_response: EventListingsResponse;
}

export type EventListingsResponse = {
    tickets: TicketsProps;
    featureFlags: TimedEntryFeatureFlag;
    eventSessions: Array<TimedEntryEventProps>;
    highlights: Pick<
        HighlightsProps,
        'freeParking' | 'paidParking' | 'notAvailableParking'
    >;
    video: NativeVideoProps;
    parentEventId: string;
};

export interface CountryProps {
    country: string | undefined;
}

export type RelatedEventsExpiredPage = {
    relatedEvents: DiscoveryEvent[];
    shareComponentProps: ShareProps;
};

export interface ControlledProps extends AppProps {
    notificationOptions: NotificationOptions | undefined;
    overlayOptions: OverlayOptions | undefined;
    creatorCollections?: CollectionResponse[];
}

export type EnhancedAppProps = AppProps & {
    creatorCollections?: CollectionResponse[];
};

interface OrganizerData extends Organizer {
    followedByYou: boolean;
    numFollowers?: number;
    numUpcomingEvents?: number;
}

export interface FollowOrganizerProps {
    organizerId: string;
    gaSettings: GASettings;
    gaCategory: string;
    organizers: string[];
    isAuthenticated: boolean;
    userId: string;
    isGDPRCountry: boolean;
    size?: string;
    organizerData?: {
        [key: string]: OrganizerData;
    };
    pageArea?: string;

    renderFollowCount?(followCount: string): JSX.Element;
}

export type RemindMeProps = {
    eventUrl?: string;
    eventName: string;
    isRemindedEvent: boolean;
    organizerId: string;
    isAuthenticated: boolean;
};

export type OrganizerPanelListingsProps = {
    organizerName: string;
    organizerId: string;
    organizerProfilePicture: string;
    organizerUrl: string;
    organizerDescription?: string;
    organizerEventTitle?: string;
    captchaKey: string;
    isAuthenticated?: boolean;
    eventName: string;
    eventId: string;
    gaCategory: string;
    orgFacebook?: string;
    orgTwitter?: string;
    orgWebsite?: string;
};

export enum SocialLinksStyle {
    sphere = 'sphere',
    rounded = 'rounded',
}

export type SocialLinksProps = {
    orgTwitter?: string;
    orgFacebook?: string;
    orgWebsite?: string;
    style?: SocialLinksStyle;
};

export type NotificationBarProps = {
    isHidden: boolean;
    type?: string;
    hasCloseButton?: boolean;
    children?: React.ReactNode;
};

export type TicketsProps = {
    ticketClasses: Array<TicketClass>;
    availability: {
        hasAvailableHiddenTickets?: boolean;
        isHoldUnavailable?: boolean;
        quantitySoldPaid?: number;
        minimumTicketPrice?: TicketPrice;
        minimumTicketPriceRounded?: TicketPrice;
        numTicketClasses?: number;
        quantitySold?: number;
        isSoldOut?: boolean;
        waitlistEnabled?: boolean;
        commonSalesEndDate?: string;
        quantitySoldFree?: number;
        quantitySoldAddOn?: number;
        waitlistAvailable?: boolean;
        maximumTicketPrice?: TicketPrice;
        isFree?: boolean;
        startSalesDate?: TicketDate;
        remainingCapacity?: number;
        hasAvailableTickets?: boolean;
        hasBogoTickets?: boolean;
        endSalesDate?: TicketDate;
        maximumTicketPriceRounded?: TicketPrice;
    };
};

export type TicketPrice = {
    currency?: string;
    majorValue?: string;
    display?: string;
    value?: number;
};

export type TicketDate = {
    timezone?: string;
    local?: string;
    utc?: string;
};

export type TrackingProps = {
    debugging: boolean;
    enabled: boolean;
    environment: Environment;
    launchSupportMultipleFacebookPixels: boolean;
    shouldFireTrackingBeacon: boolean;
    shouldTrackEventView: boolean;
    staticEventCache: boolean;
    trackingBeaconsSerialized: any;
};

// GA tracking dropdown
export enum GACodes {
    GA_ACTION_APP_LOAD = 'AppLoad',
    GA_ACTION_BOOKMARK_ADD = 'BookmarkEvent',
    GA_ACTION_BOOKMARK_REMOVE = 'UnbookmarkEvent',
    GA_ACTION_CHECKOUT = 'NewListingTicketsClick',
    GA_ACTION_COLLECTIONS_MODAL_LINK_CLICK = 'AllCollectionsModalLinkClick',
    GA_ACTION_COLLECTION_PAGE_LINK_CLICK = 'CollectionPageLinkClick',
    GA_ACTION_CONTACT_ORG_ATTEMPT_EVENT = 'ContactOrgAttempt',
    GA_ACTION_DIRECTIONS = 'Directions',
    GA_ACTION_EDIT_EVENT = 'EditEvent',
    GA_ACTION_MAP_INTERACTION = 'MapInteract',
    GA_ACTION_SHARE_EVENT = 'Share',
    GA_ACTION_REMINDME = 'RemindMeButton',
    GA_ACTION_UNREMINDME = 'TurnOffRemindMeButton',
    GA_ACTION_SHOW_LIKE_GDPR_MODAL = 'ShowLikeGDPRModal',
    GA_ACTION_SOCIAL_LINK_CLICK = 'ViewOrg',
    GA_ACTION_SELECT_DATE_ATTEMPT = 'SelectDateAttempt',
    GA_ACTION_SELECT_DATE = 'SelectDate',
    GA_ACTION_RSVP_LOADED = 'RSVPLoaded',
    GA_ACTION_RSVP_YES_INTENT = 'RSVPYesIntent',
    GA_ACTION_RSVP_MAYBE = 'RSVPMaybe',
    GA_ACTION_RSVP_NO = 'RSVPNo',
    GA_CATEGORY_COLLECTION_CARD = 'collectioncard',
    GA_CATEGORY_CREATOR_COLLECTIONS_LISTING = 'creator-collections-listing',
    GA_CATEGORY_EVENT_CARD = 'eventcard',
    GA_CATEGORY_LISTING = 'listing',
    GA_LABEL_OPEN_MODAL = 'open-modal',
    GA_LABEL_SHELF = 'shelf',
    GA_LABEL_EVENT_DETAILS = 'event-details',
    GA_LABEL_BIKE = 'bicycling',
    GA_LABEL_BUS = 'transit',
    GA_LABEL_CAN_EDIT = 'CanEdit',
    GA_LABEL_CANNOT_EDIT = 'CannotEdit',
    GA_LABEL_DRIVE = 'drive',
    GA_LABEL_WALK = 'walk',
    GA_LABEL_ZOOM = 'zoom-change',
    GA_LABEL_OLD_LISTING_ZOOM = 'old-listing-zoom-change',
    GA_LABEL_FACEBOOK = 'fb',
    GA_LABEL_TWITTER = 'tw',
    GA_LABEL_WEBSITE = 'website',
    GA_LABEL_BOOKMARK_EVENTS_REMINDER_OK = 'LikeGDPRConfirmationOk',
    GA_LABEL_BOOKMARK_EVENTS_REMINDER_NO = 'LikeGDPRConfirmationNo',
    GA_LABEL_SECONDARY = 'secondary',
    GA_LABEL_SERIES_PARENT = 'SelectADate',
    GA_LABEL_RSVP_MAYBE_MSG_SENT = 'RSVPMaybeMessageSent',
    GA_LABEL_RSVP_NO_MSG_SENT = 'RSVPNoMessageSent',
}

// Affiliate codes
export enum AffiliateCodes {
    AFF_RELATED_EVENTS_LIVE = 'erellivmlt', // Related events for live source events
    AFF_RELATED_EVENTS_SAME_ORG = 'erelpanelorg', // Related events for the same organizer
    AFF_RELATED_EVENTS_SAME_COLLECTION = 'odclrlmeitc', // Related events in the same collection
    AFF_RELATED_EVENTS_EXPIRED = 'erelexpmlt', // Related events on expired event pages
    AFF_COLLECTIONS_FEATURING_THIS_EVENT = 'odclrlmctfte', // More collections featuring this event
}

export enum CheckoutType {
    Compact = 'compact',
    OnlyCheckout = 'onlyCheckout',
}

export type TimedEntryEventProps = {
    id: string;
    startDatetime: string;
    endDatetime: string;
    availability: string;
    isActive?: boolean;
    salesStatus?: string;
};

export type TimedEntryCalenderEventProps = {
    dates: Array<{
        date: string;
        sessionCount: number;
        availability: string;
        sessions: Array<TimedEntryEventProps>;
    }>;
};

export type TimedEntryNextConfirmedEventsProps = {
    eventSessions: Array<TimedEntryEventProps>;
};

export type FormattedDate = {
    fullDate: string;
    fullDateWeekday: string;
    numeralDate: string;
    shortDate: string;
    shortDateTime: string;
    shortTime: string;
    time: string;
};

export type NativeVideoProps = {
    url: string;
    thumbnail: string;
};

export type TimeSlot = {
    time: string;
    salesStatus?: string;
};

export interface bboxShape {
    nw?: LatLong;
    se?: LatLong;
    ne?: LatLong;
    sw?: LatLong;
}

export type LatLong = {
    lng: number;
    lat: number;
};

export interface LocationData {
    latitude?: number;
    longitude?: number;
    bbox?: bboxShape;
    placeId?: string | null;
    isOnline?: boolean;
    suggestions?: any[];
    slug?: string;
    currentPlace?: string;
    currentPlaceParent?: string;
    country?: string;
    placeType?: string;
    isWaitingForLocation?: boolean;
    isUsingCurrentLocation?: boolean;
    locationDenied?: boolean;
}
