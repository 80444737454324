import React, { useContext, useEffect, useState } from 'react';

import classNames from 'classnames';

import { SegmentedCarouselContext } from './context';
import styles from './SegmentedCarousel.module.scss';

export interface SegmentedCarouselSegmentProps {
    /**
     * All props here are private
     */
    children: React.ReactNode;
    /**
     * Width of segment
     */
    width: string;
    /**
     * Checks if first segment
     */
    isFirstSegmentInPage: boolean;
    /**
     * Checks if last segment
     */
    isLastSegmentInPage: boolean;
    /**
     * Checks if last segment in entire carousel
     */
    isLastSegmentInCarousel: boolean;
    /**
     * Handles keyUp functionality for accessibility
     */
    handleKeyUp: React.KeyboardEventHandler<HTMLDivElement>;
}

export interface SegmentedCarouselSegmentChildProps {
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    onKeyUp?: React.KeyboardEventHandler<HTMLDivElement>;
}

export const SegmentedCarouselSegment: React.FC<
    SegmentedCarouselSegmentProps
> = ({
    children,
    isFirstSegmentInPage,
    isLastSegmentInPage,
    isLastSegmentInCarousel,
    width,
    handleKeyUp,
}: SegmentedCarouselSegmentProps): JSX.Element => {
    const { rootPaddingRight, syncedPadding } = useContext(
        SegmentedCarouselContext,
    );
    const [childProps, setChildProps] =
        useState<SegmentedCarouselSegmentChildProps>({});
    const segmentClasses = classNames(
        styles.segment,
        'segmented-carousel__segment',
    );

    // pass child event handlers into segment
    useEffect(() => {
        // get the child
        const arrayChildren = React.Children.toArray(children);
        const child = arrayChildren[0];

        // get the child's props
        if (React.isValidElement<SegmentedCarouselSegmentChildProps>(child)) {
            setChildProps(child.props);
        }
    }, [children, setChildProps]);

    return (
        <div
            data-testid="segmented-carousel-segment"
            role="button"
            tabIndex={0}
            className={segmentClasses}
            style={{
                minWidth: width,
                maxWidth: width,
                paddingRight: isLastSegmentInCarousel
                    ? rootPaddingRight + syncedPadding
                    : undefined,
            }}
            data-isfirstsegmentinpage={isFirstSegmentInPage}
            data-islastsegmentinpage={isLastSegmentInPage}
            onKeyUp={(e) => {
                handleKeyUp(e);
                childProps?.onKeyUp?.(e);
            }}
            onClick={childProps?.onClick}
        >
            {children}
        </div>
    );
};
