import { gettext } from '@eventbrite/i18n';
import { Button } from '@eventbrite/marmalade';
import React from 'react';
import { isGeoEligibleForSeasonal } from '../utils/ribbonEligibility';
import './SeasonalRibbonListings.scss';

export interface SeasonalRibbonProps {
    isMobile?: boolean;
    locationSlug: string;
    country: string;
    browseSurface?: string;
}

export const SeasonalRibbonListings = (props: SeasonalRibbonProps) => {
    const { isMobile, locationSlug, country, browseSurface } = props;

    const ctaLink = `/b/${locationSlug}/holiday/`;
    const ribbonTitleDesktop = gettext(
        'This holiday, we’ve got just the thing. \u{1F384}',
    );
    const ribbonTitleMobile = gettext('Holiday events are here. \u{1F384}');
    const ribbonCtaTextDesktop = gettext('Find your perfect plan.');
    const ribbonCtaTextMobile = gettext('Find your perfect plan.');

    if (!isGeoEligibleForSeasonal(country)) {
        return null;
    }

    return (
        <div className="seasonal-ribbon">
            <div className="seasonal-ribbon-container">
                <div
                    className="seasonal-ribbon-title-container"
                    data-testid="seasonal-ribbon-title"
                >
                    <h2 className="eds-text-bm">
                        {isMobile ? ribbonTitleMobile : ribbonTitleDesktop}
                    </h2>
                </div>
                <div className="seasonal-ribbon-cta">
                    <a
                        href={ctaLink || ''}
                        data-heap-id={`seasonal_ribbon_click_${browseSurface}`}
                    >
                        <Button
                            className="seasonal-ribbon-button"
                            aria-label="Find holiday events"
                        >
                            <span className="eds-text-bm">
                                {isMobile
                                    ? ribbonCtaTextMobile
                                    : ribbonCtaTextDesktop}
                            </span>
                        </Button>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default SeasonalRibbonListings;
