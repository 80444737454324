import {
    EVENTBRITE_CATEGORY_TO_REFINEMENT,
    findCategoryByTitle,
    getSubCategoriesByCategory,
} from '@eventbrite/categories';
import React, { useState } from 'react';
import { ScrollableDrawer } from '../ScrollableDrawer/ScrollableDrawer';
import { Modal } from '../_shared/Modal';
import { BrowseSubCategories } from './BrowseSubCategories';
import './BrowseSubCategories.scss';

export const BrowseSubCategoriesContainer = ({
    category,
    isMobile,
}: {
    category?: string;
    isMobile: boolean;
}) => {
    // State to manage the visibility of a modal
    const [isModalOpen, setIsModalOpen] = useState(true);

    if (
        !category ||
        typeof window === 'undefined' ||
        typeof document === 'undefined'
    ) {
        return null;
    }

    //Fetches all its subcategories for a parent category
    const parentCategory = findCategoryByTitle(category);
    const subcategories = parentCategory
        ? getSubCategoriesByCategory(parentCategory)
        : null;

    if (!parentCategory || !subcategories) {
        return null;
    }

    // Render the content based on whether it's mobile or desktop
    if (isMobile) {
        return (
            <ScrollableDrawer>
                <BrowseSubCategories
                    category={category}
                    subcategories={subcategories}
                    isMobile={isMobile}
                    categoryRefinement={
                        EVENTBRITE_CATEGORY_TO_REFINEMENT[parentCategory]
                    }
                />
            </ScrollableDrawer>
        );
    } else {
        return (
            <Modal
                visible={isModalOpen}
                noPadding={true}
                type="simple"
                __containerClassName="modalCss"
                onClose={() => setIsModalOpen(false)}
            >
                <BrowseSubCategories
                    category={category}
                    subcategories={subcategories}
                    categoryRefinement={
                        EVENTBRITE_CATEGORY_TO_REFINEMENT[parentCategory]
                    }
                />
            </Modal>
        );
    }
};
