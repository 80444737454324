import { HighlightsProps } from '../../Highlights';
import type { Widget } from '../Widgets/types';

export interface EventDescriptionProps extends Summary, LegacyDescription {
    shouldShowSalesEndedMessage: boolean | null;
    organizerSalesEndedMessage: string | null;
    salesEndedMessage: string | null;
    hasStructuredContent: boolean;
    structuredContent: StructuredContent;
}

type StructuredContent = {
    hasAutogeneratedContent: boolean | null;
    modules: StructuredContentModule[];
    widgets: Widget[];
};

type Summary = {
    summary: string;
};

export type LegacyDescription = {
    legacyDescription: string;
    highlights?: HighlightsProps;
};

export interface AboutThisEventProps
    extends Summary,
        Omit<StructuredContent, 'widgets'> {
    highlights?: HighlightsProps;
    category?: string;
    subcategory?: string;
}

export enum StructuredContentModuleType {
    TEXT = 'text',
    IMAGE = 'image',
    VIDEO = 'video',
}

export type StructuredContentModule = {
    type: StructuredContentModuleType;
} & (TextContentProps | ImageContentProps | VideoContentProps);

export enum TextContentAlignment {
    LEFT = 'left',
    CENTER = 'center',
    RIGHT = 'right',
}

export type TextContentProps = {
    text: string;
    alignment: TextContentAlignment;
};

export type ImageContentProps = {
    url: string;
    altText?: string;
    captionText?: string;
};

export type VideoContentProps = {
    url: string;
    altText?: string;
};
