import styled from '@emotion/styled';
import {
    Accordion,
    AccordionContent,
    AccordionHeader,
} from '@eventbrite/eds-accordion';
import { day as EDSColors } from '@eventbrite/eds-settings';
import React from 'react';
import { LISTING_HEAP_IDS } from '../../../../constants';
import { FaqWidget } from './types';

interface Props {
    data: FaqWidget;
}

export const Faq: React.FC<Props> = ({ data }) => {
    return (
        <FaqWrapper
            data-testid="Faq"
            data-heap-id={LISTING_HEAP_IDS.FAQS_DROPDOWN}
        >
            {data.faqs?.map(({ question, answer }, index) => (
                <Accordion key={index}>
                    <AccordionHeader>
                        <HeaderContent>{question}</HeaderContent>
                    </AccordionHeader>
                    <AccordionContent>
                        <FaqContent>{answer}</FaqContent>
                    </AccordionContent>
                </Accordion>
            ))}
        </FaqWrapper>
    );
};

export const FaqWrapper = styled.div`
    background: white;
    padding: 10px 0;
`;

export const HeaderContent = styled.div`
    margin-left: -16px;
    margin-right: -16px;
`;

export const FaqContent = styled.p`
    background-color: ${EDSColors['ui-100']};
    border-radius: 8px;
    line-height: 20px;
    padding: 16px;
`;
