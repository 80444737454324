import { EventAction, EventActionType, SetSeriesEventAction } from '../actions';
import type { EventBasicInformation } from '../types';
import { setEarlyBird } from './setEarlyBird';
import { setEventSessionTimedEntry } from './setEventSessionTimedEntry';
import { setEventURL } from './setEventURL';
import { setTimedEntryEvents } from './setTimedEntryEvents';

const reducersByType = {
    [EventActionType.setSeriesEvent]: (
        event: EventBasicInformation,
        { payload: { id, start, end, url, salesStatus } }: SetSeriesEventAction,
    ): EventBasicInformation => ({
        ...event,
        id,
        start,
        end,
        url,
        salesStatus,
    }),
    [EventActionType.setEventURL]: setEventURL,
    [EventActionType.setEarlyBird]: setEarlyBird,
    [EventActionType.updateChildEventForTimedEntry]: setTimedEntryEvents,
    [EventActionType.updateEventSessionForTimedEntry]:
        setEventSessionTimedEntry,
};

export function eventReducer(
    event: EventBasicInformation,
    action: EventAction,
): EventBasicInformation {
    const reducer = reducersByType[action.type];

    if (reducer) {
        return reducer(event, action as any);
    }

    throw Error('Unknown event action: ' + action.type);
}
