import type {
    DestinationEvent,
    DestinationResponse,
    FormattedEvent,
    FormattedEventResponse,
} from '@eventbrite/event-renderer';
import type {
    CreatorBadgeResponse,
    CreatorSignalQualityResponse,
    IRatingResponse,
    OrganizerEventResponse,
    OrganizerEventsResponse,
    RelatedEventsApiResponse,
} from './types/api';

import isEmpty from 'lodash/isEmpty';
import { formatUrl } from 'url-lib';

import { transformDestinationEvent } from '@eventbrite/event-renderer';
import { sdkRequest, translateServerErrors } from '@eventbrite/http';
import { gettext } from '@eventbrite/i18n';
import {
    getNavigatorLastRecentSearchTerms,
    getNavigatorLastRecentViews,
} from '@eventbrite/personalization';
import { searchEventRelatedPromotedEvents } from '../_shared/searchEventRelatedPromotedEvents';

import { EventBasicInformation } from '../../contexts/EventContext';

const STANDARD_SERVER_ERROR = gettext('An error occured.');

const GENERAL_ERROR_SPEC = {
    default: () => STANDARD_SERVER_ERROR,
};

export const _getOrganizerEventsApiUrl = (organizerId: string): string =>
    `/organizers/${organizerId}/events/?expand=ticket_availability&status=live&only_public=true`;

export const _getCreatorQualitySignalApiUrl = (organizerId: string): string =>
    `/creator-quality-signal/organizer/${organizerId}`;

export const _getCreatorBadgeApiUrl = (organizerId: string): string =>
    `/creator-quality-signal/organizer/${organizerId}/badge`;

export const _getOrganizerDestinationEventsApiUrl = (
    eventIds: string,
): string =>
    `/destination/events/?event_ids=${eventIds}&expand=event_sales_status,ticket_availability,image,primary_venue,saves,primary_organizer&include_parent_events=true`;

const _getRatingsApiUrl = (organizerId: string) => {
    return `/feedback-service/organizer/${organizerId}/rating`;
};

export const excludeDuplicatedEvents = (
    events: FormattedEvent[],
    eventIdsToExclude: string[],
) => events.filter((event) => !eventIdsToExclude.includes(event.id));

export const getRelatedEvents = async (
    isAuthenticated: boolean,
    event: EventBasicInformation,
): Promise<FormattedEventResponse> => {
    const eventId = event?.id.toString() || '';

    return getRelatedEventsAndPromoted(isAuthenticated, event)
        .then(({ organic_events, promoted_events }) => {
            const events = organic_events.filter(
                (event: DestinationEvent) => event.id !== eventId,
            );
            return {
                events: events.map(transformDestinationEvent),
                promotedEvents: promoted_events.map(transformDestinationEvent),
            };
        })
        .catch(translateServerErrors(GENERAL_ERROR_SPEC));
};

async function getRelatedEventsAndPromoted(
    isAuthenticated: boolean,
    event: EventBasicInformation,
): Promise<RelatedEventsApiResponse> {
    const [organicEventsSearchResponse, promotedEventsSearchResponse] =
        await Promise.allSettled([
            fetchRelatedEvents(isAuthenticated, event),
            searchEventRelatedPromotedEvents(event),
        ]);

    if (organicEventsSearchResponse.status === 'rejected') {
        throw new Error(organicEventsSearchResponse.reason);
    }

    return {
        organic_events: organicEventsSearchResponse.value.organic_events,
        promoted_events:
            promotedEventsSearchResponse.status === 'fulfilled'
                ? promotedEventsSearchResponse.value
                : [],
    };
}

function _getUserStatsFromLocalStorage(isAuthenticated: boolean) {
    const formattedPreviousSearches = getNavigatorLastRecentSearchTerms(5);

    const views = getNavigatorLastRecentViews();

    const userStatsData = {
        user_recent_searches: formattedPreviousSearches,
        user_recent_views: views,
        logged_in: isAuthenticated || false,
        browse_surface: 'listings',
    };

    return {
        method: 'POST',
        body: JSON.stringify(userStatsData),
    };
}

export async function fetchRelatedEvents(
    isAuthenticated: boolean,
    event: EventBasicInformation,
): Promise<RelatedEventsApiResponse> {
    const response = await sdkRequest(
        `/destination/events/${event.id}/related/?expand=image,primary_venue,ticket_availability,saves,primary_organizer`,
        _getUserStatsFromLocalStorage(isAuthenticated),
    );

    return {
        organic_events: response.events,
        promoted_events: response.promoted_events,
    };
}

export const getOrganizerEvents = (
    organizerId: string,
): Promise<OrganizerEventsResponse> =>
    sdkRequest(_getOrganizerEventsApiUrl(organizerId))
        .then((response: OrganizerEventsResponse) => response)
        .catch(translateServerErrors(GENERAL_ERROR_SPEC));

export const getOpenSaleEvents = (
    events: OrganizerEventResponse[],
): OrganizerEventResponse[] =>
    events?.filter(
        (event: OrganizerEventResponse) =>
            event.ticket_availability?.has_available_tickets,
    );

export const filterEvents = (
    amountOfEvents: number,
    events: any[],
    eventOwnerId: string,
) => {
    const eventsWithoutOwner = events?.filter((event: any) => {
        return event.id !== eventOwnerId;
    });
    return eventsWithoutOwner?.slice(0, amountOfEvents);
};

export const NUMBER_OF_MORE_EVENTS_TO_SHOW = 3;

export const getMoreEventsFromThisOrganizerFiltered = (
    eventOwnerId: string,
    events: OrganizerEventResponse[],
): OrganizerEventResponse[] => {
    const openSaleEvents = getOpenSaleEvents(events);
    const filteredEvents = filterEvents(
        NUMBER_OF_MORE_EVENTS_TO_SHOW,
        openSaleEvents,
        eventOwnerId,
    );

    return filteredEvents;
};

export const getOrganizerRelatedEventsFromDestination = async (
    organizerId: string,
    eventId: string,
): Promise<FormattedEventResponse | undefined> => {
    const { events } = await getOrganizerEvents(organizerId);

    const filteredEvents = getMoreEventsFromThisOrganizerFiltered(
        eventId,
        events,
    );
    const relatedEventIds = filteredEvents.map((event) => event.id);

    if (isEmpty(relatedEventIds)) {
        return;
    }

    return sdkRequest(
        _getOrganizerDestinationEventsApiUrl(relatedEventIds.join()),
    )
        .then((response: DestinationResponse) => ({
            events: response.events.map(transformDestinationEvent),
        }))
        .catch(translateServerErrors(GENERAL_ERROR_SPEC));
};

export const getCreatorQualitySignal = (
    organizerId: string,
): Promise<CreatorSignalQualityResponse> => {
    return sdkRequest(formatUrl(_getCreatorQualitySignalApiUrl(organizerId)))
        .then((response: CreatorSignalQualityResponse) => response)
        .catch(translateServerErrors(GENERAL_ERROR_SPEC));
};

export const getCreatorBadgeSignal = (
    organizerId: string,
): Promise<CreatorBadgeResponse> => {
    return sdkRequest(formatUrl(_getCreatorBadgeApiUrl(organizerId)))
        .then((response: CreatorBadgeResponse) => response)
        .catch(translateServerErrors(GENERAL_ERROR_SPEC));
};

export const getRatingsData = async (organizerId: string) => {
    const response: IRatingResponse = await sdkRequest(
        _getRatingsApiUrl(organizerId),
    );

    return response?.ratings;
};
