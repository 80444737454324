import { track } from '@eventbrite/datalayer-library';
import { logEvent } from '@eventbrite/statsig';
import { RefObject, useState } from 'react';
import { useIntersecting } from './useIntersecting';

type UseTrackComponentProps = {
    domNode?: HTMLElement | null;
    ref?: RefObject<HTMLElement>;
    heap?: {
        eventName: string;
        eventBucketLabel?: string;
    };
    statsig?: {
        eventName: string;
        params?: {
            [key: string]: string;
        };
    };
    rootMargin?: string;
};

export const useTrackComponent = ({
    domNode,
    ref,
    heap,
    statsig,
    rootMargin = '0px',
}: UseTrackComponentProps) => {
    const [viewed, setViewed] = useState(false);

    const [isIntersecting] = useIntersecting({
        domNode: domNode,
        ref: ref,
        rootMargin: rootMargin,
    });

    if (isIntersecting && !viewed) {
        setViewed(true);
        if (heap) {
            track({
                eventName: heap.eventName,
                eventData: {
                    eventBucketLabel: heap.eventBucketLabel,
                },
            });
        }

        if (statsig) {
            logEvent(statsig.eventName, null, statsig.params || null);
        }
    }
};
