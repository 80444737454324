import { getExperiment, getLayer } from '@eventbrite/statsig';
import { useEffect, useState } from 'react';

export enum StatsigEnrollmentType {
    LAYER = 'layer',
    EXPERIMENT = 'experiment',
}

export enum GetEnrollmentStatus {
    LOADING = 'loading',
    SUCCESS = 'success',
    DISABLED = 'disabled',
}

export type GetStatsigEnrollmentResponse<T> = {
    status: GetEnrollmentStatus;
    variant: T;
};

const ENROLLMENT_FN_MAP = {
    [StatsigEnrollmentType.EXPERIMENT]: getExperiment,
    [StatsigEnrollmentType.LAYER]: getLayer,
};

interface IUseGetStatsigEnrollment {
    name: string;
    paramName: string;
    defaultValue: any;
    enabled?: boolean;
    enrollmentType?: StatsigEnrollmentType;
}

/**
 * Hook assumes the statsig SDK
 * has been initialized and returns the
 * expected variant. It is not listening
 * for the initialization to re-trigger
 * fetching an experiment.
 *
 * Until mounted on the client, the hook will return
 * 'loading'. Allowing server side content to
 * render appropriately until the variant
 * is provided.
 *
 * This is a stopgap solution for any
 * statsig experiments within the server.tsx file
 * that gets intermittently read incorrectly during
 * server side rendering.
 *
 */
export function useGetStatsigEnrollment<T>({
    name,
    paramName,
    defaultValue,
    enabled = true,
    enrollmentType = StatsigEnrollmentType.EXPERIMENT,
}: IUseGetStatsigEnrollment): GetStatsigEnrollmentResponse<T> {
    const [enrollmentVariantValue, setEnrollmentVariantValue] =
        useState<T>(defaultValue);

    const [status, setGetEnrollmentStatus] = useState<GetEnrollmentStatus>(
        !enabled ? GetEnrollmentStatus.DISABLED : GetEnrollmentStatus.LOADING,
    );

    useEffect(() => {
        if (enabled) {
            const getVariant = ENROLLMENT_FN_MAP[enrollmentType];

            const variant = getVariant(name, paramName, defaultValue);

            setEnrollmentVariantValue(variant);
            setGetEnrollmentStatus(GetEnrollmentStatus.SUCCESS);
        }
    }, [name, paramName, defaultValue, enabled, enrollmentType]);

    return {
        status,
        variant: enrollmentVariantValue,
    } as GetStatsigEnrollmentResponse<T>;
}
