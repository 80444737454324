import React from 'react';
import { useTestAAExperiment } from '../../experimentation';
import { useIsServerSideRendered } from '../hooks/useIsServerSideRendered';

export const TestAASSR = () => {
    const isSSRExperiment = useTestAAExperiment();
    const { isServerSideRendered } = useIsServerSideRendered();

    return (
        <div
            id={`test-aa-${isSSRExperiment}-ssr-${isServerSideRendered}`}
        ></div>
    );
};
