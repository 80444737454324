import { IconButton } from '@eventbrite/eds-icon-button';
import { HeartChunky, HeartFillChunky } from '@eventbrite/eds-iconography';
import { withTooltip } from '@eventbrite/eds-tooltip';
import { setWindowLocation } from '@eventbrite/http';
import { gettext } from '@eventbrite/i18n';
import loadable from '@loadable/component';
import React, { useContext, useState } from 'react';
import { UserContext } from '../../context';
import {
    EventBasicInformation,
    EventBasicInformationContext,
} from '../../contexts/EventContext';
import { isReduceLoginFrictionExperiment } from '../../experimentation/experiments';
import './Bookmark.scss';

export type BookmarkProps = {
    eventId?: string;
    isBookmarkedEvent: boolean;
    onClickLoggedIn: () => void;
};

const STYLE_BRAND = 'brand';
const STYLE_NEUTRAL = 'neutral';

const LazyBookmarkModalLoggedOut = loadable(
    () => import('./BookmarkModalLoggedOut'),
);

const bookmarkedEventLookup = (isBookmarked: boolean) =>
    isBookmarked
        ? {
              className: 'bookmark is-active',
              iconType: <HeartFillChunky />,
              iconStyle: STYLE_BRAND,
          }
        : {
              className: 'bookmark',
              iconType: <HeartChunky />,
              iconStyle: STYLE_NEUTRAL,
          };

export const Bookmark = (props: BookmarkProps) => {
    const { id: eventId } = useContext(
        EventBasicInformationContext,
    ) as EventBasicInformation;
    const { isBookmarkedEvent, onClickLoggedIn } = props;
    const { isAuthenticated } = useContext(UserContext);
    const isBookmarkedEventProps = bookmarkedEventLookup(isBookmarkedEvent);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const IconButtonWithTooltip = withTooltip(IconButton);

    const handleClickLoggedOut = () => {
        if (isReduceLoginFrictionExperiment()) {
            redirectToLogin();
            return;
        }
        setIsModalVisible(true);
    };

    const redirectToLogin = () => {
        const encodedURI = encodeURIComponent(
            `${window.location.pathname}?bookmarkEvent=${eventId}`,
        );
        const loginWithReferrerPath = `/login/?referrer=${encodedURI}`;
        return setWindowLocation(loginWithReferrerPath);
    };

    const renderBookmarkButton = () => {
        const commonProps = {
            iconType: isBookmarkedEventProps.iconType,
            tooltipId: 'tooltip-bookmark',
            tooltipStyle: 'basic',
            title: gettext('Like Event'),
            tooltipText: gettext('Like Event'),
            style: isAuthenticated
                ? isBookmarkedEventProps.iconStyle
                : STYLE_NEUTRAL,
            onClick: isAuthenticated ? onClickLoggedIn : handleClickLoggedOut,
        };

        return (
            <div className={isBookmarkedEventProps.className}>
                <IconButtonWithTooltip
                    aria-pressed={isAuthenticated && isBookmarkedEvent}
                    {...commonProps}
                />
            </div>
        );
    };

    return (
        <>
            {renderBookmarkButton()}
            {!isAuthenticated && isModalVisible && (
                <LazyBookmarkModalLoggedOut
                    visible={isModalVisible}
                    onClose={() => setIsModalVisible(false)}
                    eventId={eventId}
                />
            )}
        </>
    );
};

export default Bookmark;
